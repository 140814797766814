export function getBookingPageUxFeatures(url) {
  var _found$kind;
  const checks = [{
    match: /\/i\//,
    kind: 'invalid'
  }, {
    match: /\/v\//,
    kind: 'valid'
  }, {
    match: /\/handover\//,
    kind: 'booking'
  }];
  const found = checks.find(_ref => {
    let {
      match
    } = _ref;
    return match.test(url);
  });
  return [(_found$kind = found === null || found === void 0 ? void 0 : found.kind) !== null && _found$kind !== void 0 ? _found$kind : 're-booking'];
}