import { useState, useEffect } from 'react';
import { TOKEN_STATUSES, AuthManager } from './auth-manager.js';
export function useAuthState() {
  const [authState, setAuthState] = useState('');
  const isAuthValid = authState === TOKEN_STATUSES.tokenValid;
  useEffect(function authEffect() {
    const manager = new AuthManager(window.localStorage);
    const token = manager.getRefreshToken();
    const state = manager.getTokenState(token);
    setAuthState(prevState => {
      if (prevState !== state) {
        return state;
      }
      return prevState;
    });
  }, []);
  return {
    authState,
    isAuthValid
  };
}