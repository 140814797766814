export const formNames = {
  //Data fields
  manufacturer: 'manufacturer',
  mainType: 'mainType',
  // model
  builtDate: 'builtDate',
  // builtYear
  bodyType: 'bodyType',
  mainTypeDetail: 'mainTypeDetail',
  // modelDetails
  mainTypeSub: 'mainTypeSub',
  // modelDetailsSub
  fuelType: 'fuelType',
  horsePower: 'horsePower',
  doorCount: 'doorCount',
  gearType: 'gearType',
  //Other fields
  licensePlate: 'licensePlate',
  mileage: 'mileage',
  exactMileage: 'exactMileage',
  location: 'location',
  email: 'email',
  honeyPotEmail: 'repeatEmail',
  emailSSOSource: 'emailSSOSource',
  EMAIL_EVALUATION_CONSENT: 'EMAIL_EVALUATION_CONSENT',
  latestCIDchannel: 'latestCIDchannel'
};