import { getReferFriendEmailCookie } from '@wkda/funnel-utils';
import merge from 'lodash-es/merge';
export function consumerCarleadCreatePayloadMapper(_ref) {
  let {
    config,
    initialPayload = {},
    values,
    partialMappers = [],
    marketingProperties,
    questionnaires
  } = _ref;
  return createBasePayloadMapper({
    config,
    initialPayload,
    mapperFn: consumerCarleadCreateBaseMapper,
    DTOMapper: consumerCarleadDAOToDTO,
    values,
    partialMappers: partialMappers.concat([pricingScopeMapper, walkInOptionMapper, referralCodeMapper]),
    marketingProperties,
    questionnaires
  });
}
export function consumerCarleadCreateWithLicensePlateMapper(_ref2) {
  let {
    config,
    initialPayload = {},
    values,
    partialMappers = [],
    marketingProperties,
    questionnaires
  } = _ref2;
  return createBasePayloadMapper({
    config,
    initialPayload,
    mapperFn: consumerCarleadCreateWithLicensePlateBaseMapper,
    DTOMapper: consumerCarleadLicensePlateDAOToDTO,
    values,
    partialMappers: partialMappers.concat([pricingScopeMapper, walkInOptionMapper, referralCodeMapper]),
    marketingProperties,
    questionnaires
  });
}
export function createBasePayloadMapper(_ref3) {
  let {
    config,
    mapperFn,
    DTOMapper,
    initialPayload = {},
    values,
    partialMappers = [],
    marketingProperties,
    questionnaires
  } = _ref3;
  const payload = applyPartialMappers({
    config,
    mappers: partialMappers,
    values
  }, mapperFn({
    config,
    initialPayload,
    values
  }));
  return DTOMapper({
    ...payload,
    marketingProperties,
    questionnaires
  });
}

// Partial Mappers
export function pricingScopeMapper(params) {
  let payload = {};
  if (params.config.featuresFlags.pricingScope !== 'none') {
    payload = {
      options: {
        pricingScope: params.config.featuresFlags.pricingScope
      }
    };
  }
  return payload;
}
export function walkInOptionMapper(params) {
  let payload = {};
  if (params.config.featuresFlags.walkInSelfEvaluation) {
    payload = {
      options: {
        walkIn: params.config.featuresFlags.walkInSelfEvaluation
      }
    };
  }
  return payload;
}
export function referralCodeMapper() {
  const referralCode = getReferFriendEmailCookie();
  let payload = {};
  if (referralCode) {
    payload = {
      referralCode: referralCode
    };
  }
  return payload;
}
export function getLandingPageName() {
  return window.location.pathname.split('/').filter(path => !!path).slice(1).join('/');
}
export function consumerCarleadCreateBaseMapper(_ref4) {
  let {
    initialPayload,
    values,
    config
  } = _ref4;
  return merge({}, {
    carLead: {
      builtYear: values.builtDate,
      datTypeId: values.mainTypeSub,
      email: values.email,
      km: values.mileage,
      landingPageName: getLandingPageName(),
      mobile: null,
      subscribed: true,
      sourceId: config.isPartnerFunnel ? null : config.sourceId
    },
    location: generateLocationObject(),
    options: {},
    marketingProperties: {},
    marketingAttributes: generateMarketingAttributes(config.marketingAttributes),
    questionnaires: []
  }, initialPayload);
}
export function consumerCarleadDAOToDTO(payload) {
  return {
    ...payload,
    marketingProperties: transFormMarketingPropertiesToArray(payload.marketingProperties)
  };
}
export function consumerCarleadCreateWithLicensePlateBaseMapper(_ref5) {
  let {
    initialPayload,
    values,
    config
  } = _ref5;
  return merge({}, {
    licensePlate: values.licensePlate,
    email: values.email,
    mileage: values.mileage,
    landingPageName: getLandingPageName(),
    //TODO: Missing logic
    // branchId: '',
    location: generateLocationObject(),
    options: {},
    marketingProperties: {},
    marketingAttributes: generateMarketingAttributes(config.marketingAttributes),
    questionnaires: [],
    sourceId: config.isPartnerFunnel ? null : config.sourceId
  }, initialPayload);
}
export function consumerCarleadLicensePlateDAOToDTO(payload) {
  return {
    ...payload,
    marketingProperties: transFormLicensePlateMarketingPropertiesToArray(payload.marketingProperties)
  };
}

// Private area

function applyPartialMappers(_ref6, initialPayload) {
  let {
    config,
    values,
    mappers = []
  } = _ref6;
  return mappers.reduce(function partialMapperReducer(payload, partialFn) {
    return merge({}, payload, partialFn({
      config,
      initialPayload: payload,
      values
    }));
  }, initialPayload);
}
function transFormMarketingPropertiesToArray(properties) {
  return Object.entries(properties).map(_ref7 => {
    let [key, value] = _ref7;
    return {
      propertyId: Number(key),
      value
    };
  });
}
function transFormLicensePlateMarketingPropertiesToArray(properties) {
  return Object.entries(properties).map(_ref8 => {
    let [key, value] = _ref8;
    return {
      id: Number(key),
      value
    };
  });
}
function generateLocationObject() {
  let environment = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : process.env.NODE_ENV;
  const nodeEnv = environment === 'development' || environment === 'test';
  return nodeEnv ? {
    ipAddress: '87.128.11.187'
  } : {};
}
function generateMarketingAttributes() {
  let marketingAttributes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return Object.entries(marketingAttributes).map(_ref9 => {
    let [name, value] = _ref9;
    return {
      name,
      value
    };
  });
}