import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useAppConfiguration } from '@wkda/funnel-components';

// Adapted from - https://github.com/wkda/dynamic-step-frontend-builder/blob/a9adfde56016ca0ed21efec91afb4afa699a53ab/src/pageElements/wkda/scripts/container-helpers/priced-cars-counter.js#L1
export function usePricedCarsCounter() {
  const carsPricedPerHour = useMemo(() => [241, 131, 72, 52, 50, 60, 163, 524, 1017, 1632, 1774, 1949, 1857, 1801, 1738, 1758, 1880, 1955, 1618, 1395, 1158, 979, 745, 455], []);
  const [count, setCount] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const {
    fullLocale
  } = useAppConfiguration();

  // Calculate initial count
  useEffect(() => {
    const calculateInitialCount = () => {
      const now = new Date();
      const currentHour = now.getHours();
      const currentMinute = now.getMinutes();
      const currentSecond = now.getSeconds();
      let totalCarsPriced = 0;
      let secondsElapsedInCurrentHour = currentMinute * 60 + currentSecond;
      for (let i = 0; i < currentHour; i++) {
        totalCarsPriced += carsPricedPerHour[i];
      }
      let carsPricedPerSecondCurrentHour = carsPricedPerHour[currentHour] / 3600;
      totalCarsPriced += carsPricedPerSecondCurrentHour * secondsElapsedInCurrentHour;
      return Math.max(1, Math.floor(totalCarsPriced));
    };
    setCount(calculateInitialCount());
    setIsLoading(false);
  }, [carsPricedPerHour]);

  // Update counter based on current hour's rate
  const updateInterval = useMemo(() => {
    if (count === null) return null;
    const currentHour = new Date().getHours();
    const carsThisHour = carsPricedPerHour[currentHour];
    const carsPerSecond = carsThisHour / 3600;
    return carsPerSecond > 0 ? 1000 / carsPerSecond : 1000;
  }, [carsPricedPerHour, count]);
  useInterval(() => {
    if (count !== null) {
      setCount(prevCount => prevCount + 1);
    }
  }, updateInterval);

  // Reset counter at midnight
  useEffect(() => {
    const setupMidnightReset = () => {
      const now = new Date();
      const msUntilMidnight = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1).getTime() - now.getTime();
      const timeoutId = setTimeout(() => {
        setCount(1);
        setupMidnightReset();
      }, msUntilMidnight);
      return () => clearTimeout(timeoutId);
    };
    return setupMidnightReset();
  }, []);

  // Format counter display
  const formattedCount = useMemo(() => {
    if (count === null) return '';
    return count.toLocaleString(fullLocale, {
      style: 'decimal',
      useGrouping: true
    });
  }, [count, fullLocale]);
  return {
    pricedCarCount: formattedCount,
    isLoading
  };
}
function useInterval(callback, delay) {
  const savedCallback = useRef();
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    const tick = () => {
      if (savedCallback.current) {
        savedCallback.current();
      }
    };
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}