import { useQuery, useMutation } from '@tanstack/react-query';
import { licensePlateFromMarketingPropertiesSelector, subtypeExtraParamsSelector, subtypeModelDetailsParamsSelector } from '@wkda/funnel-modules';
import { useModulesStore } from '../useModules';
import { useAppConfiguration } from '../useAppConfiguration';
import useCarleadHashParam from '../useCarleadHashParam';
import { useGetConsumerCarlead, useGetConsumerSelfEvaluation, useConsumerSourceId, useVehicleNameFromConsumerCarlead } from './consumerCarlead';
export { useGetOrCreateSelfEvaluation, useGetSelfEvaluation, useGetSelfEvaluationPreview, useIdentifyOnSubmitSelfEvaForm, useOnSubmitSelfEvaForm, useSelfEvaHowItWorksInfo, useUpdatePhoneAndImTypePatch, useSavePartialSelfEvaluation, useCreateSelfEvaluationAssumption } from './selfEvaluationHooks';
export { useVinVerificationOptionsRequest, useEditableVinVerificationOptionsRequest } from './useVinVerificationOptionsRequest';
export { useDoubleOptInConfig } from './useDoubleOptInConfig';
export { useGetConsumerCarlead, useGetConsumerSelfEvaluation, useConsumerSourceId, useAppConfiguration, useVehicleNameFromConsumerCarlead };
export { useGetAssumptionData } from './useGetAssumptionData';
export { useGetCarCrm } from './carCrm';
export * from './marketingProperties';
export const CONFLICT_ERROR = 409;
export const NOT_FOUND_ERROR = 404;
export function useLayoutQuery() {
  const {
    partialsModule
  } = useModulesStore();
  const {
    fullLocale
  } = useAppConfiguration();
  return useQuery({
    queryKey: ['partials'],
    queryFn: () => partialsModule.getAllPartials(fullLocale),
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });
}
export function useSubscribeToNewsletter() {
  const {
    subscriptionModule
  } = useModulesStore();
  return useMutation({
    mutationFn: subscriptionModule.subscribeToNewsletter
  });
}
export function useSelfEvaOnlinePriceInfo() {
  const {
    partialsModule
  } = useModulesStore();
  const {
    fullLocale
  } = useAppConfiguration();
  return useQuery({
    queryKey: ['onlinePriceInfo'],
    queryFn: () => partialsModule.getSelfEvaOnlinePriceInfo(fullLocale),
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchOnMount: false,
    retry: QueryNotFoundRetryFn
  });
}
export function useGetMarketingAttributes() {
  const {
    marketingAttributesModule
  } = useModulesStore();
  const {
    getMarketingAttributes
  } = marketingAttributesModule;
  const carleadHash = useCarleadHashParam();
  return useQuery({
    queryKey: ['marketingAttributes', carleadHash],
    queryFn: () => getMarketingAttributes(carleadHash),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: QueryNotFoundRetryFn
  });
}
export function useGetExperiment() {
  let name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'self-eva-experiment-snippet';
  const {
    partialsModule
  } = useModulesStore();
  const {
    fullLocale,
    abExperiment
  } = useAppConfiguration();
  return useQuery({
    queryKey: ['experimentsScript', name],
    queryFn: () => partialsModule.getExperiment(fullLocale, name, undefined, abExperiment.partialGroupId),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: QueryNotFoundRetryFn,
    enabled: name != null && typeof name !== 'undefined' && typeof abExperiment !== 'undefined'
  });
}
export function useGetSelfEvaBanners() {
  const {
    partialsModule
  } = useModulesStore();
  const {
    fullLocale
  } = useAppConfiguration();
  return useQuery({
    queryKey: ['selfEvaBanners', fullLocale],
    queryFn: () => partialsModule.getSelfEvaBanners(fullLocale),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: QueryNotFoundRetryFn
  });
}

/**
 * @returns {import('@tanstack/react-query').UseQueryResult<import('@wkda/funnel-modules').IExtraMappingLicensePlate | {}, any>}
 */
export function useGetLicensePlateMapping() {
  const {
    locale
  } = useAppConfiguration();
  const {
    licensePlateMappingModule
  } = useModulesStore();
  const {
    data: consumer
  } = useGetConsumerCarlead();
  const licensePlate = licensePlateFromMarketingPropertiesSelector(consumer);
  const enabled = Boolean(licensePlate && (locale === 'sv-SE' || locale === 'nl'));
  return useQuery({
    queryKey: ['license-plate-mapping', licensePlate],
    queryFn() {
      return licensePlateMappingModule.getMappingWithLicensePlate(licensePlate);
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: QueryNotFoundRetryFn,
    enabled
  });
}
export function useGetSubtypeModelDetails() {
  const {
    locale,
    allowedLocalesForSubtypesData
  } = useAppConfiguration();
  const {
    carTypesModule
  } = useModulesStore();
  const carleadHash = useCarleadHashParam();
  const {
    data: consumer,
    status
  } = useGetConsumerCarlead();
  const params = subtypeModelDetailsParamsSelector(consumer, locale);
  const allowedLocales = allowedLocalesForSubtypesData;
  return useQuery({
    queryKey: ['cartypes-model-details', carleadHash, params],
    queryFn() {
      return carTypesModule.getMainTypesDetails(params);
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: QueryNotFoundRetryFn,
    enabled: allowedLocales.includes(locale) && status === 'success'
  });
}

/**
 * @param {Partial<import('@wkda/funnel-modules').IGetMainTypesSubParameters>} [params]
 */
export function useGetSubtypeExtra() {
  let params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const {
    locale,
    allowedLocalesForSubtypesData = []
  } = useAppConfiguration();
  const {
    carTypesModule
  } = useModulesStore();
  const carleadHash = useCarleadHashParam();
  const {
    data: consumer,
    status
  } = useGetConsumerCarlead();
  const paramsFromConsumer = subtypeExtraParamsSelector(consumer, locale);
  let enabled = allowedLocalesForSubtypesData.includes(locale) && status === 'success';

  // Filter out undefined or empty params.
  Object.keys(params).forEach(k => {
    if (typeof params[k] === 'undefined' || params[k] === '') delete params[k];
  });
  const _params = {
    ...paramsFromConsumer,
    ...params
  };
  return useQuery({
    queryKey: ['cartypes-subtype-extra', carleadHash, _params],
    queryFn() {
      return carTypesModule.getSubtypeExtra(_params);
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: QueryNotFoundRetryFn,
    enabled
  });
}
export function useCookieBannerQuery() {
  const {
    partialsModule
  } = useModulesStore();
  const {
    fullLocale,
    cookieBannerName
  } = useAppConfiguration();
  return useQuery({
    queryKey: ['cookieBanner', fullLocale],
    queryFn: () => partialsModule.getCookieBanner(fullLocale, cookieBannerName),
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });
}
export function QueryNotFoundRetryFn(count, error) {
  var _error$response;
  if ((error === null || error === void 0 || (_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.status) === 404) {
    return false;
  }
  return count < 3;
}