import create from 'zustand';
import { devtools } from 'zustand/middleware';
export const useWizardStore = create(devtools(wizardStore));
function wizardStore(set) {
  return {
    wizard: null,
    setWizard: wizard => set(() => ({
      wizard
    }))
  };
}