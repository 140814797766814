import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { DataLayer } from '@wkda/data-layer';
export class BaseDataLayer extends DataLayer {
  static getInstance() {
    if (!this.self) {
      this.self = new this();
    }
    return this.self;
  }
  init(_ref) {
    let {
      locale
    } = _ref;
    if (!this.initialized) {
      this.add({
        locale
      });
      this.initialized = true;
    }
  }
}
_defineProperty(BaseDataLayer, "self", null);