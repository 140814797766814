import { useState } from 'react';
import { ModalType } from './modalType';

/**
 * @typedef {{[k in ModalType]: string[]}} ModalMap
 */

/**
 * @typedef {(type: ModalType, id: string) => void} RegisterFn
 */

/**
 * @typedef {Object} ModalManagerState
 * @property {ModalMap} modals
 * @property {RegisterFn} register
 */

export const defaultState = {
  modals: {
    [ModalType.MANDATORY]: [],
    [ModalType.OTHER]: []
  }
};
export function useModalManagerState() {
  const [modalManagerState, setModalManagerState] = useState(defaultState);

  /**
   * @param {ModalType} type
   * @param {string} id
   */
  const register = (type, id) => {
    return setModalManagerState(prevState => {
      const isAlreadyRegistered = prevState.modals[type].includes(id);
      if (isAlreadyRegistered) {
        throw new Error("Modal of type=\"" + type + "\" with id=\"" + id + "\" was already registered.");
      }
      return {
        ...prevState,
        modals: {
          ...prevState.modals,
          [type]: [...prevState.modals[type], id]
        }
      };
    });
  };

  /**
   * @param {ModalMap} modals
   * @param {ModalType} type
   * @param {string} id
   */
  function shouldRender(modals, type, id) {
    if (!modals[type].includes(id)) return;

    // First render mandatory modals, starting from the first registered modal
    if (type === ModalType.MANDATORY) return modals[ModalType.MANDATORY][0] === id;

    // Render all other modals
    return modals[ModalType.MANDATORY].length === 0;
  }

  /**
   * @param {ModalType} type
   * @param {string} id
   */
  function unregister(type, id) {
    return setModalManagerState(prevState => {
      return {
        ...prevState,
        modals: {
          ...prevState.modals,
          [type]: prevState.modals[type].filter(modalId => modalId !== id)
        }
      };
    });
  }
  return {
    state: modalManagerState,
    setModalManagerState,
    register,
    unregister,
    shouldRender: (type, id) => shouldRender(modalManagerState.modals, type, id)
  };
}