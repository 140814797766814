export function createErrorMessages(i18n) {
  return {
    manufacturer: i18n._('carleadCreation.error.manufacturer'),
    mainType: i18n._('carleadCreation.error.mainType'),
    builtDate: i18n._('carleadCreation.error.builtDate'),
    bodyType: i18n._('carleadCreation.error.bodyType'),
    mainTypeDetail: i18n._('carleadCreation.error.mainTypeDetail'),
    mainTypeSub: i18n._('carleadCreation.error.mainTypeSub'),
    licensePlate: i18n._('carleadCreation.error.licensePlate'),
    mileage: i18n._('carleadCreation.error.mileage'),
    exactMileage: i18n._('carleadCreation.error.exactMileage'),
    tooLongMileage: i18n._('carleadCreation.error.tooLongMileage'),
    location: i18n._('carleadCreation.error.location'),
    email: i18n._('carleadCreation.error.email'),
    EMAIL_EVALUATION_CONSENT: i18n._('carleadCreation.error.emailConsent'),
    missingValue: i18n._('carleadCreation.error.missingValue'),
    selectAnswer: i18n._('carleadCreation.error.selectAnswer'),
    fuelType: i18n._('carleadCreation.error.fuelType'),
    horsePower: i18n._('carleadCreation.error.horsePower'),
    doorCount: i18n._('carleadCreation.error.doorCount'),
    gearType: i18n._('carleadCreation.error.gearType')
  };
}