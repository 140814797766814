import { BaseDataLayer } from './BaseDataLayer';
export class CarleadDataLayer extends BaseDataLayer {
  pushCustomerBannerOpen(banner) {
    this.addEvent('event.customerBanner.open', {
      'customer-banner': banner
    });
  }
  pushSelfevaBanner(step) {
    this.addEvent('event.customerBanner.selfEvaluation.cta', {
      selfInspectionBanner: "step" + step
    });
  }
  pushQuestionnaireChangedEvent(name, value) {
    this.addEvent("event.surveyQuestion." + name + ".changed", {
      [name + "-answer"]: value
    });
  }
  pushError(key, error) {
    this.addEvent('event.carleadError', {
      key,
      error
    });
  }
  leadFieldChanged(fieldName, source) {
    this.addEvent('event.leadFormFieldChanged', {
      leadFieldChanged: fieldName,
      source
    });
  }
}