import { dehydrate } from '@tanstack/react-query';
import { getWKDALeadCookie, prefetchExperiment, prefetchGdprCookieContent, setSCIDCookie } from '@wkda/funnel-components';
import { getUsableErrors, setReferFriendEmailCookie } from '@wkda/funnel-utils';
export async function carleadCreationGetInitialProps(_ref) {
  let {
    req,
    res,
    appConfig,
    modules,
    locale,
    abTestingEnabled,
    queryClient
  } = _ref;
  const {
    fullLocale,
    abExperiment,
    funnelBaseUrl,
    cookieBannerName
  } = appConfig;
  const {
    cidModule,
    partialsModule,
    consumerModule,
    logger,
    appointmentModule
  } = modules;
  const CIDArray = cidModule.getCIDCookie(req);
  // used to send the 8700 marketing property for carlead all carlead creation funnels
  const latestCIDchannel = getLatestCIDChannel(CIDArray);
  try {
    const queries = [];
    setReferFriendEmailCookie(req, res);
    if (req) {
      await setSCIDCookie(appConfig, logger, req, res);
      await cidModule.trackCidAsCookie({
        locale,
        cmsUrl: funnelBaseUrl
      }, req, res);
    }
    const wkdaLead = getWKDALeadCookie(req);
    queries.push(queryClient.prefetchQuery({
      queryKey: ['averaged-priced-counter'],
      queryFn: consumerModule.getAveragePriceCounter
    }), queryClient.prefetchQuery({
      queryKey: ['partials'],
      queryFn: () => partialsModule.getAllPartials(fullLocale)
    }), prefetchGdprCookieContent(queryClient, partialsModule.getCookieBanner, fullLocale, cookieBannerName));
    if (wkdaLead) {
      queries.push(queryClient.prefetchQuery({
        queryKey: ['consumerCarlead', wkdaLead],
        queryFn: () => consumerModule.getConsumerCarlead(wkdaLead)
      }), queryClient.prefetchQuery({
        queryKey: ['lastAppointment', wkdaLead],
        queryFn: () => appointmentModule.getLastAppointments(wkdaLead)
      }));
    }
    queries.push(prefetchExperiment(queryClient, modules.partialsModule, fullLocale, abExperiment === null || abExperiment === void 0 ? void 0 : abExperiment.experimentSnippetName, abExperiment === null || abExperiment === void 0 ? void 0 : abExperiment.partialGroupId));
    await Promise.all(queries);
    return {
      locale,
      abTestingEnabled,
      latestCIDchannel,
      CID: CIDArray,
      dehydratedState: dehydrate(queryClient)
    };
  } catch (error) {
    return {
      locale,
      abTestingEnabled,
      latestCIDchannel,
      CID: CIDArray,
      //@ts-expect-error TODO: check error type here
      initialError: getUsableErrors(error),
      dehydratedState: dehydrate(queryClient)
    };
  }
}
function getLatestCIDChannel(cid) {
  var _cid$at;
  return cid ? (_cid$at = cid.at(-1)) === null || _cid$at === void 0 ? void 0 : _cid$at.channel : undefined;
}