import { injectDataLayer, DataLayer } from '@wkda/data-layer';

import type { IDataLayerService } from './data-layer-service.interface';

export function createDataLayerService(): IDataLayerService {
  const dataLayer = DataLayer.getInstance();

  return {
    injectDataLayer,
    push: dataLayer.add.bind(dataLayer),
    pushEvent: dataLayer.addEvent.bind(dataLayer),
    unshiftEvent: dataLayer.unshiftEvent.bind(dataLayer),
    unshift: dataLayer.unshift.bind(dataLayer),
  };
}
