import { useQuery } from '@tanstack/react-query';
import { carTypeResponseAsOptionsSelector } from '@wkda/funnel-modules';
export function useDynamicCartypesFetcher(_ref) {
  var _query$data;
  let {
    enabled,
    name,
    queryFn,
    params,
    responseTransformConfig = {}
  } = _ref;
  const query = useQuery({
    queryKey: [name, params].filter(Boolean),
    enabled,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: 300,
    queryFn: () => {
      return queryFn(params);
    },
    select(data) {
      return carTypeResponseAsOptionsSelector(data, responseTransformConfig.orderBy, responseTransformConfig.order);
    },
    notifyOnChangeProps: ['data', 'error']
  });
  return {
    ...query,
    data: (_query$data = query.data) !== null && _query$data !== void 0 ? _query$data : []
  };
}