import Cookies from 'universal-cookie';
import { SCID_COOKIE, useModulesStore } from '@wkda/funnel-components';
import { getExperimentData } from '@wkda/funnel-utils';
import { useCarleadConfiguration } from './useCarleadCreationConfiguration';
export function useCarleadCjTracking() {
  const {
    cjTrackingModule: {
      cjTracking
    }
  } = useModulesStore();
  const {
    appName,
    sourceId
  } = useCarleadConfiguration();
  function getEventData() {
    const experimentData = getExperimentData();
    const {
      screen,
      location
    } = window;
    const cookies = new Cookies();
    const scid = cookies.get(SCID_COOKIE);
    return {
      appname: appName,
      category: 'lead-creation',
      source: "" + sourceId,
      screenSize: {
        width: screen === null || screen === void 0 ? void 0 : screen.width,
        height: screen === null || screen === void 0 ? void 0 : screen.height
      },
      currentUrl: location === null || location === void 0 ? void 0 : location.href,
      landingPageName: location === null || location === void 0 ? void 0 : location.pathname,
      currentExperimentId: experimentData === null || experimentData === void 0 ? void 0 : experimentData.id,
      currentVariant: experimentData === null || experimentData === void 0 ? void 0 : experimentData.variant,
      scid
    };
  }
  return {
    trackSuccess(carleadHash) {
      cjTracking({
        ...getEventData(),
        hash: carleadHash,
        action: 'lead.cta.success'
      });
    },
    trackError(key, error) {
      cjTracking({
        ...getEventData(),
        hash: '',
        action: 'event.carleadError',
        key,
        error
      });
    }
  };
}