import { i18n as _i18n } from "@lingui/core";
export const getHappyCustomerSliderCarleadContent = slider => {
  const sliderContent = {
    happyCustomersSlim: {
      id: 'happy-customer-mobile-slider',
      headline: {
        id: 'carlead.happyCustomersSlim.title',
        message: 'Sold! Our Happy Customers nation Wide'
      },
      slides: []
    }
  };
  const dataCustomPositions = ['90%', '70%', '45%', '78%', '60%', '45%', '60%', '65%', '60%', '67%'];
  for (let i = 1; i <= 10; i++) {
    sliderContent.happyCustomersSlim.slides.push({
      name: {
        id: "carlead.happyCustomersSlim.slide" + i + ".name"
      },
      car: {
        id: "carlead.happyCustomersSlim.slide" + i + ".car"
      },
      quote: {
        id: "carlead.happyCustomersSlim.slide" + i + ".quote"
      },
      image: {
        'src': _i18n._(
        /*i18n*/
        {
          id: "carlead.happyCustomersSlim.slide" + i + ".image"
        }),
        'alt': 'Happy customer image',
        'data-custom-position': dataCustomPositions[i - 1]
      }
    });
  }
  return sliderContent[slider];
};