import { formNames } from './formNames';
export function createMKTSelectors() {
  let names = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : formNames;
  return {
    [names.manufacturer]: createSelector(names.manufacturer),
    [names.mainType]: createSelector(names.mainType),
    [names.builtDate]: createSelector(names.builtDate),
    [names.bodyType]: createSelector(names.bodyType),
    [names.mainTypeDetail]: createSelector(names.mainTypeDetail),
    [names.mainTypeSub]: createSelector(names.mainTypeSub),
    [names.licensePlate]: createSelector(names.licensePlate),
    [names.mileage]: createSelector(names.mileage),
    [names.location]: createSelector(names.location),
    [names.email]: createSelector(names.email),
    [names.honeyPotEmail]: createSelector(names.honeyPotEmail),
    [names.fuelType]: createSelector(names.fuelType),
    [names.horsePower]: createSelector(names.horsePower),
    [names.gearType]: createSelector(names.gearType),
    [names.doorCount]: createSelector(names.doorCount)
  };
}
function createSelector(name) {
  let group = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  return {
    value: name,
    group
  };
}