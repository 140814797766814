import { getConsumerDataForVehicleName } from '@wkda/funnel-modules';
import { useQuery } from '@tanstack/react-query';
import useCarleadHashParam from '../useCarleadHashParam';
import { useModulesStore } from '../useModules';
import { getVehicleName } from '@wkda/funnel-utils';
export function useGetConsumerCarlead(carleadHash) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const {
    consumerModule
  } = useModulesStore();
  const hash = useCarleadHashParam() || carleadHash;
  return useQuery({
    queryKey: ['consumerCarlead', hash],
    queryFn: () => consumerModule.getConsumerCarlead(hash),
    enabled: Boolean(hash),
    ...options,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });
}
export function useGetConsumerSelfEvaluation(carleadHash) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const {
    consumerModule
  } = useModulesStore();
  const hash = useCarleadHashParam() || carleadHash;
  return useQuery({
    queryKey: ['consumerSelfEvaluation', hash],
    queryFn: () => consumerModule.getConsumerSelfEvaluation(hash),
    enabled: Boolean(hash),
    retry: false,
    ...options,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });
}
export function useVehicleNameFromConsumerCarlead() {
  const hash = useCarleadHashParam();
  return useGetConsumerCarlead(hash, {
    select(data) {
      return getVehicleName(getConsumerDataForVehicleName(data));
    }
  });
}
export function useConsumerSourceId() {
  var _consumerCarlead$data, _consumerCarlead$data2;
  const consumerCarlead = useGetConsumerCarlead();
  return consumerCarlead !== null && consumerCarlead !== void 0 && (_consumerCarlead$data = consumerCarlead.data) !== null && _consumerCarlead$data !== void 0 && _consumerCarlead$data.sourceId ? "" + (consumerCarlead === null || consumerCarlead === void 0 || (_consumerCarlead$data2 = consumerCarlead.data) === null || _consumerCarlead$data2 === void 0 ? void 0 : _consumerCarlead$data2.sourceId) : '';
}
export function useConsumerCarleadMarketingProperty(propertyName) {
  var _consumerCarlead$carM;
  const {
    marketingPropertiesModule
  } = useModulesStore();
  const {
    data: consumerCarlead
  } = useGetConsumerCarlead();
  const property = ((_consumerCarlead$carM = consumerCarlead === null || consumerCarlead === void 0 ? void 0 : consumerCarlead.carMarketingProperties) !== null && _consumerCarlead$carM !== void 0 ? _consumerCarlead$carM : []).find(property => property.propertyId === marketingPropertiesModule.propertyIds[propertyName]);
  return property;
}
export function useConsumerCarleadCarProperty(propertyId) {
  var _consumerCarlead$carP;
  const {
    data: consumerCarlead
  } = useGetConsumerCarlead();
  const property = ((_consumerCarlead$carP = consumerCarlead === null || consumerCarlead === void 0 ? void 0 : consumerCarlead.carProperties) !== null && _consumerCarlead$carP !== void 0 ? _consumerCarlead$carP : []).find(property => property.propertyId === propertyId);
  return property;
}