export const scrollNextField = () => {
  const current = document.querySelector('.carlead-creation-field-container--is-current');
  const lastField = document.querySelector('.carlead-creation-form-button');
  const emailFilled = document.querySelector('.carlead-creation-field-container--email.carlead-creation-field-container--is-selected + .carlead-creation-field-container--EMAIL_EVALUATION_CONSENT');
  const scrollToElement = element => {
    const offset = 100;
    const elementTop = element.getBoundingClientRect().top + window.scrollY;
    const targetPosition = elementTop - offset;
    window.scrollTo({
      top: targetPosition,
      behavior: 'smooth'
    });
  };
  if (emailFilled) {
    scrollToElement(emailFilled);
  } else if (current !== null) {
    scrollToElement(current.previousSibling || current);
  } else {
    scrollToElement(lastField);
  }
};