const emailRegex =
// eslint-disable-next-line no-useless-escape
/^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const fakeEmailPattern = '@no-email.auto1.com';
export function isValidEmailAddress(email) {
  return !isFakeEmail(email) && isValidEmailPattern(email);
}
export function isValidEmailPattern() {
  let email = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return emailRegex.test(email);
}
export function isFakeEmail() {
  let email = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return email.endsWith(fakeEmailPattern);
}