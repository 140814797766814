function isFocusableInput(input) {
  return !!(input && typeof input.focus === 'function');
}
function getAllInputs() {
  if (typeof document === 'undefined') {
    return [];
  }
  return Array.from(document.forms).reduce((accumulator, form) => {
    //@ts-expect-error
    return accumulator.concat(Array.from(form).filter(isFocusableInput));
  }, []);
}
export default getAllInputs;