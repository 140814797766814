//      
var maybeAwait = function maybeAwait(maybePromise, callback) {
  if (maybePromise && typeof maybePromise.then === 'function') {
    // async
    maybePromise.then(callback);
  } else {
    // sync
    callback();
  }
};

var createDecorator = function createDecorator(_ref) {
  var beforeSubmit = _ref.beforeSubmit,
      afterSubmitSucceeded = _ref.afterSubmitSucceeded,
      afterSubmitFailed = _ref.afterSubmitFailed;
  return function (form) {
    var originalSubmit = form.submit;

    form.submit = function () {
      if (beforeSubmit) {
        if (beforeSubmit(form) === false) {
          return;
        }
      }

      var result = originalSubmit.call(form);

      if (afterSubmitSucceeded || afterSubmitFailed) {
        maybeAwait(result, function () {
          var _form$getState = form.getState(),
              submitSucceeded = _form$getState.submitSucceeded,
              submitFailed = _form$getState.submitFailed;

          if (afterSubmitSucceeded && submitSucceeded) {
            afterSubmitSucceeded(form);
          }

          if (afterSubmitFailed && submitFailed) {
            afterSubmitFailed(form);
          }
        });
      }

      return result;
    };

    return function () {
      form.submit = originalSubmit;
    };
  };
};

//

export default createDecorator;
