import { parse } from 'query-string';
export class QueryStringCache {
  // TODO: Implement setItem
  setItem(_key, _item) {}
  getItem(_key) {
    if (typeof window === 'undefined') {
      return null;
    }
    return parse(window.location.search);
  }
  onChange(_key, _handler) {
    return () => {};
  }
  // TODO: Implement removeItem
  removeItem(_key) {}
}