import { removeLicensePlateSeparators } from './helpers';
export function validateLicensePlateByLocale(locale, rawValue) {
  if (!rawValue) return false;
  const value = removeLicensePlateSeparators(rawValue);
  const l = locale.toLowerCase();
  switch (l) {
    case 'sv-se':
      return /^[A-Z]{3}\s?\d{2}(\d|[A-Z])$/gi.test(value);
    case 'it':
      return /^(?:(?!i|o|q|u)[a-z]){2}[0-9]{3}(?:(?!i|o|q|u)[a-z]){2}$/gi.test(value);
    case 'da':
      return /^[A-Z]{2}\s?\d{2}\s?\d{3}$/g.test(value);
    case 'fr':
      return validateFRLicensePlate(value);
    case 'es':
      return validateESLicensePlate(value);
    case 'pl':
      // Should accept letters and numbers, mininum 1 character, maximum 10 characters
      return /^[a-zA-Z0-9]{1,10}$/g.test(value);
    default:
      return true;
  }
}
function validateFRLicensePlate(value) {
  const newPattern = /^[a-z]{2}[0-9]{3}[a-z]{2}$/gi;
  const oldPatternBase = /^[0-9]{1,4}[a-z]{1,3}[0-9]{2}$/gi;
  const oldPatternSpectialTerritory = /^[0-9]{1,4}[a-z]{1,3}(2[a|b])$/gi;
  const oldPatternOverseas = /^[0-9]{1,4}[a-z]{1,3}(97[1-8])$/gi;
  const patterns = [newPattern, oldPatternBase, oldPatternSpectialTerritory, oldPatternOverseas];
  for (let i = 0; i < patterns.length; i++) {
    if (patterns[i].test(value)) {
      return true;
    }
  }
  return false;
}
function validateESLicensePlate(value) {
  const newPattern = /^[0-9]{4}[b-z]{3}$/gi;
  const oldPattern = /^[a-z]{1,3}[0-9]{4}[a-z]{2}$/gi;
  const patterns = [newPattern, oldPattern];
  for (let i = 0; i < patterns.length; i++) {
    if (patterns[i].test(value)) {
      return true;
    }
  }
  return false;
}