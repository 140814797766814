export function isIOS() {
  try {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) || navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1;
  } catch {
    return false;
  }
}
export function getIsElementIntersecting(element) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    threshold: 0.3
  };
  return new Promise(resolve => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    }, options);
    observer.observe(element);
  });
}
export function getIsInIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    // Security error, assuming it is in an iframe
    return true;
  }
}