import { createPersistDecorator } from '@wkda/funnel-components';
import { DataLocalStorage, isLocalStorageSupported } from '@wkda/funnel-utils';
import { useCallback, useMemo } from 'react';
import { mergeWith } from 'lodash-es';
import { useCarleadConfiguration } from '../hooks/useCarleadCreationConfiguration';
import { DSBDataLocalStorage } from './DSBDataLocalStorage';
import { DSBDataQueryStringStorage } from './DSBDataQueryStringStorage';
import { QueryStringCache } from './QueryStringCache';
import { mapFromQuery } from './queryObjectMapper';
const mergeObjects = (target, source) => {
  return mergeWith({}, target, source, (targetValue, sourceValue) => {
    if (sourceValue === '') {
      return targetValue;
    }
    // Handle with default mergeWith customizer
    return undefined;
  });
};
export function useCacheStorage() {
  const {
    locale,
    fullLocale,
    featuresFlags: {
      exactMileage
    }
  } = useCarleadConfiguration();
  const storage = useMemo(() => {
    return isLocalStorageSupported() ? new DSBDataLocalStorage(new DataLocalStorage(), {
      locale,
      fullLocale,
      exactMileage
    }) : new DSBDataQueryStringStorage(new QueryStringCache(), {
      locale,
      fullLocale,
      exactMileage
    });
  }, [locale, fullLocale, exactMileage]);
  const getData = useCallback(function getData() {
    function getDataItemFromQueryString() {
      try {
        const query = new QueryStringCache().getItem(storage.formStorageKey);
        return mapFromQuery(query);
      } catch (e) {
        console.error("[Form Cache] Failed to get fallback data from query string", e);
        return null;
      }
    }
    const item = storage.getItem(storage.formStorageKey);
    // See: https://wkdauto.atlassian.net/browse/CFE-17183
    // Partner DSB widgets sets localStorage data on different domain thus we cannot access the values on actual domain after redirect with query params.
    // To prevent this we need to take account the query params when creating preloaded data.
    const queryDataItem = getDataItemFromQueryString();
    try {
      let result = JSON.parse(item);
      if (queryDataItem !== null) {
        // We merge localStorage data with query string values and localStorage data takes priorty over query string.
        // However if localStorage data values have empty value ("") then we use query string values for fields.
        // We only take account query strings that related to data fields (manufacturer, model, builtYear).
        result = mergeObjects(queryDataItem, result);
      }
      return result;
    } catch (e) {
      console.error("[Form Cache] Error getting data: " + item, e);
      return null;
    }
  }, [storage]);
  const setData = useCallback(function setData(values) {
    storage.setItem(storage.formStorageKey, JSON.stringify(values));
  }, [storage]);
  const API = useMemo(() => {
    return {
      storage,
      getData,
      setData
    };
  }, [storage, getData, setData]);
  return API;
}
export function usePersistDecorator() {
  const {
    storage
  } = useCacheStorage();
  const persistDecorator = useMemo(() => {
    return createPersistDecorator({
      name: storage.formStorageKey,
      debounceTime: 100,
      // TODO: DSBCoreDataLocalStorage is missing some of the Storage methods
      // Probable it's better to fork `final-form-persist` to make `storage` property more generic
      // Current implementaion is using only `setItem`, `getItem` and `removeItem` methods
      // @ts-expect-error
      storage
    });
  }, [storage]);
  return persistDecorator;
}