import { useEffect, useState } from 'react';
export function useWindowUnloadStatus() {
  const [windowUnloaded, setWindowUnloaded] = useState(false);
  const handleWindowUnload = () => {
    setWindowUnloaded(true);
  };
  useEffect(() => {
    window.addEventListener('unload', handleWindowUnload);
    return () => {
      window.removeEventListener('unload', handleWindowUnload);
      handleWindowUnload();
    };
  }, []);
  return {
    windowUnloaded
  };
}