import { DataLayerEventStack } from '@wkda/data-layer';
import { useEffect, useRef } from 'react';
export function useDataLayerWithEventStack(DataLayerClass, locale) {
  const dataLayer = useRef(DataLayerClass.getInstance());
  useEffect(() => {
    const dataLayerEventStack = new DataLayerEventStack();
    dataLayerEventStack.init();
    dataLayer.current.init({
      locale
    });
  }, [locale]);
  return dataLayer.current;
}