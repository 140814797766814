import { setup } from 'xstate';
import { assignDataOnSelectAction, assignOnPreloadAndClearAction, assignResetValuesAction, clearResetToAction, fillInputAction, focusOnEnterAction, resetValuesAndStackAction, selectFieldToFocusAction, selectWithPreloadedAction, sendSelectAfterResetAction, updateStackAction } from './shared/actions';
import { canModifyFieldGuard, canSelectFieldGuard, canSelectQuestionnaireFieldGuard, canUpdateFieldGuard, hasPreloadedDataForDataFieldGuard, hasPreloadedDataGuard, shouldFocusInputGuard, shouldModifyFieldGuard, shouldTransitionToNextStateWithValueGuard, stepValueIsFromAnswersGuard } from './shared/guards';
export function createNormalFlowMachineConfig(configObject) {
  const machine = setup({
    types: {
      context: {},
      events: {},
      input: {}
    },
    actors: {},
    actions: {
      focusOnEnter: focusOnEnterAction,
      fillInput: fillInputAction(),
      //actions related to preloading the machine data for DSB support
      //action needed to for data fields preload
      //it will add the stepName to stepToPreload and value to stepValueToPreload
      //that will be used when calling selectWithPreloaded on the next microtick
      //also clear the fieldName from the preloaded data, this is needed to not allow
      //the guard enter this state again during the rest of th lifecycle
      assignOnPreloadAndClear: assignOnPreloadAndClearAction(),
      //This action is called right after the above action 👆🏼
      //it will pick from the context and send the select action
      //to go to the next logic state
      //this is not called to preload questionnaire fields
      selectWithPreloaded: selectWithPreloadedAction(),
      assignDataOnSelect: assignDataOnSelectAction(),
      updateStack: updateStackAction(),
      clearResetTo: clearResetToAction(),
      // Called after user select previous field
      // the state will go to reseting state and then
      // call this action to actually select the value for that field
      sendSelectAfterReset: sendSelectAfterResetAction(),
      assignResetValues: assignResetValuesAction(),
      resetValuesAndStack: resetValuesAndStackAction(),
      selectFieldToFocus: selectFieldToFocusAction(),
      pushToDataLayerCache: (_args, __params) => {},
      pushQuestionnaireDataLayerEvent: (_args, __params) => {},
      pushQuestionToDataLayerCache: (_args, __params) => {},
      syncQuestionnaireCookies: (_args, __params) => {},
      pushDataLayerEvent: (_args, __params) => {},
      blurOnEnter: (_args, __params) => {}
    },
    guards: {
      hasPreloadedData: hasPreloadedDataGuard,
      hasPreloadedDataForDataField: hasPreloadedDataForDataFieldGuard,
      canSelectField: canSelectFieldGuard,
      shouldModifyField: shouldModifyFieldGuard,
      shouldFocusInput: shouldFocusInputGuard,
      canSelectQuestionnaireField: canSelectQuestionnaireFieldGuard,
      canModifyField: canModifyFieldGuard,
      canUpdateField: canUpdateFieldGuard,
      shouldTransitionToNextStateWithValue: shouldTransitionToNextStateWithValueGuard,
      stepValueIsFromAnswers: stepValueIsFromAnswersGuard
    }
  }).createMachine({
    id: 'funnelForm',
    context: _ref => {
      var _input$dataFields, _input$otherFields, _input$questionnaireF, _input$preloadedData;
      let {
        input
      } = _ref;
      return {
        stack: [],
        dataFields: (_input$dataFields = input.dataFields) !== null && _input$dataFields !== void 0 ? _input$dataFields : [],
        otherFields: (_input$otherFields = input.otherFields) !== null && _input$otherFields !== void 0 ? _input$otherFields : [],
        questionnaireFields: (_input$questionnaireF = input.questionnaireFields) !== null && _input$questionnaireF !== void 0 ? _input$questionnaireF : [],
        preloadedData: (_input$preloadedData = input.preloadedData) !== null && _input$preloadedData !== void 0 ? _input$preloadedData : {},
        resetTo: '',
        resetValue: '',
        resetType: '',
        values: {},
        stepToPreload: '',
        stepValueToPreload: '',
        updatingField: '',
        selectedFields: {},
        previousSelectedFieldsMap: {}
      };
    },
    on: {
      SELECT: [{
        target: '.calculateStateToReset',
        guard: {
          type: 'shouldModifyField'
        },
        actions: [{
          type: 'assignResetValues'
        }]
      }],
      FOCUS_INPUT: [{
        target: '.calculateStateToFocus',
        guard: {
          type: 'shouldFocusInput'
        },
        actions: [{
          type: 'selectFieldToFocus'
        }]
      }]
    },
    ...configObject
  });
  return machine;
}