import isNull from 'lodash-es/isNull';
import mergeWith from 'lodash-es/mergeWith';
import { formNames } from './formNames';
export function createInitialValues(_ref) {
  let {
    defaultValues = {},
    defaultValuesSelector = (objValue, defaultValue) => isNull(objValue) ? defaultValue : objValue
  } = _ref;
  return function create() {
    return mergeWith(defaultInitialValues(), defaultValues, defaultValuesSelector);
  };
}
function defaultInitialValues() {
  return {
    [formNames.manufacturer]: '',
    [formNames.mainType]: '',
    [formNames.builtDate]: '',
    [formNames.bodyType]: '',
    [formNames.mainTypeDetail]: '',
    [formNames.mainTypeSub]: '',
    [formNames.licensePlate]: '',
    [formNames.mileage]: '',
    [formNames.location]: null,
    [formNames.email]: '',
    [formNames.honeyPotEmail]: '',
    [formNames.emailSSOSource]: null
  };
}