export function isRiskFree(carLeadData) {
  var _carLeadData$data;
  const carPurchaseTrackId = 7100;
  let isRiskFree = false;
  let riskFreeValue = 'risk-free';
  const carProperties = carLeadData === null || carLeadData === void 0 || (_carLeadData$data = carLeadData.data) === null || _carLeadData$data === void 0 ? void 0 : _carLeadData$data.carProperties;
  if (!carProperties) return;
  for (let i = 0; i < carProperties.length; i++) {
    const prop = carProperties[i];
    if (prop.propertyId === carPurchaseTrackId && prop.value === riskFreeValue) {
      isRiskFree = true;
    }
  }
  return isRiskFree;
}
export function isValid(carLeadData) {
  var _carLeadData$data2;
  const priceExpiresInDays = carLeadData === null || carLeadData === void 0 || (_carLeadData$data2 = carLeadData.data) === null || _carLeadData$data2 === void 0 ? void 0 : _carLeadData$data2.priceExpiresInDays;
  const isValid = priceExpiresInDays !== 0;
  return isValid;
}
export function getPriceStatus(carLeadData) {
  const riskFree = isRiskFree(carLeadData);
  const valid = isValid(carLeadData);
  const statusMap = {
    expired: 'expired',
    reeva: 'reeva',
    valid: 'valid'
  };
  if (valid) return statusMap.valid;
  if (!valid && riskFree) return statusMap.expired;
  if (!valid && !riskFree) return statusMap.reeva;
  return '';
}