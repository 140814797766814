import { useBookingAppConfiguration } from '../../hooks/useBookingAppConfiguration';
import { useHandoverProps } from './useHandoverProps';
export function useMileagePriceTag() {
  var _handoverProps$car, _handoverProps$car2;
  const {
    showLowMileageTag,
    showHighMileageTag,
    mileageTagThresholds
  } = useBookingAppConfiguration();
  const handoverProps = useHandoverProps();
  const carYear = handoverProps === null || handoverProps === void 0 || (_handoverProps$car = handoverProps.car) === null || _handoverProps$car === void 0 ? void 0 : _handoverProps$car.builtYear;
  const carMileage = handoverProps === null || handoverProps === void 0 || (_handoverProps$car2 = handoverProps.car) === null || _handoverProps$car2 === void 0 ? void 0 : _handoverProps$car2.km;
  const carYearThreshold = mileageTagThresholds && mileageTagThresholds[carYear];
  const carYearThresholdLow = carYearThreshold === null || carYearThreshold === void 0 ? void 0 : carYearThreshold.low;
  const carYearThresholdHigh = carYearThreshold === null || carYearThreshold === void 0 ? void 0 : carYearThreshold.high;
  const lowMileageTagShown = showLowMileageTag && carMileage && carYearThresholdLow && carMileage < carYearThresholdLow;
  const highMileageTagShown = showHighMileageTag && carMileage && carYearThresholdHigh && carMileage > carYearThresholdHigh;
  return {
    showLowMileageTag,
    showHighMileageTag,
    lowMileageTagShown,
    highMileageTagShown,
    carMileage,
    carYear,
    carYearThresholdLow,
    carYearThresholdHigh
  };
}