import { AuthManager } from '@wkda/auth-manager';
import { localStore } from './safeStorage';
export function getAuthTokenDetails() {
  if (typeof window === 'undefined') {
    return {};
  }
  const manager = new AuthManager(localStore);
  const token = manager.getRefreshToken();
  if (!token) {
    return {};
  }
  return manager.getDetailsFromToken(token);
}
export function getCustomerAuthentication() {
  if (typeof window === 'undefined') {
    return {};
  }
  const manager = new AuthManager(localStore);
  return manager.getCustomerAuthentication();
}