import { useQueryClient } from '@tanstack/react-query';
import { formNames } from '@wkda/carlead-creation-form';
import { useModulesStore } from '@wkda/funnel-components';
import { useCarleadConfiguration } from '../../../hooks/useCarleadCreationConfiguration';
import { getFetcherActor } from '../shared/getFetcherActor';
export function useSplitFieldsMachineServices() {
  const queryClient = useQueryClient();
  const {
    carTypesSplitModule
  } = useModulesStore();
  const {
    fullLocale,
    country
  } = useCarleadConfiguration();
  const languageParams = {
    country,
    locale: fullLocale
  };
  const getFetchActor = getFetcherActor(queryClient, languageParams);
  return {
    fetchManufacturers: getFetchActor([], formNames.manufacturer, carTypesSplitModule.getManufacturers),
    fetchMainTypes: getFetchActor(['manufacturer'], formNames.mainType, carTypesSplitModule.getMainTypes),
    fetchBuiltDates: getFetchActor(['manufacturer', 'mainType'], formNames.builtDate, carTypesSplitModule.getBuiltDates),
    fetchBodyTypes: getFetchActor(['manufacturer', 'mainType', 'builtDate'], formNames.bodyType, carTypesSplitModule.getBodyTypes),
    fetchFuelTypes: getFetchActor(['manufacturer', 'mainType', 'builtDate', 'bodyType'], formNames.fuelType, carTypesSplitModule.getFuelTypes),
    fetchMainTypeDetails: getFetchActor(['manufacturer', 'mainType', 'builtDate', 'bodyType', 'fuelType'], formNames.mainTypeDetail, carTypesSplitModule.getMainTypesDetails),
    fetchHorsePower: getFetchActor(['manufacturer', 'mainType', 'builtDate', 'bodyType', 'fuelType', 'mainTypeDetail'], formNames.horsePower, carTypesSplitModule.getHorsePower),
    fetchGearTypes: getFetchActor(['manufacturer', 'mainType', 'builtDate', 'bodyType', 'fuelType', 'mainTypeDetail', 'horsePower'], formNames.gearType, carTypesSplitModule.getGearTypes),
    fetchDoorCount: getFetchActor(['manufacturer', 'mainType', 'builtDate', 'bodyType', 'fuelType', 'mainTypeDetail', 'horsePower', 'gearType'], formNames.doorCount, carTypesSplitModule.getDoorCount),
    fetchMainTypeSub: getFetchActor(['manufacturer', 'mainType', 'builtDate', 'bodyType', 'fuelType', 'mainTypeDetail', 'horsePower', 'gearType', 'doorCount'], formNames.mainTypeSub, carTypesSplitModule.getMainTypesSub)
  };
}