export default function trustpilotLeadConfig(locale, screen) {
  var _localeScreenConfig$l;
  const baseConfig = {
    className: 'trustpilot-widget',
    theme: 'light',
    target: '_blank',
    rel: 'noopener',
    label: 'Trustpilot',
    stars: '4,5',
    clickable: true
  };
  const localeScreenConfig = {
    'de': {
      desktop: {
        locale: 'de-DE',
        templateId: '5419b6ffb0d04a076446a9af',
        businessUnitId: '557b5f2e0000ff00058029ab',
        href: 'https://de.trustpilot.com/review/wirkaufendeinauto.de',
        height: '28px',
        width: '100%'
      },
      mobile: {
        locale: 'de-DE',
        templateId: '5419b732fbfb950b10de65e5',
        businessUnitId: '557b5f2e0000ff00058029ab',
        href: 'https://de.trustpilot.com/review/wirkaufendeinauto.de',
        height: '24px',
        width: '100%'
      }
    },
    'it': {
      desktop: {
        locale: 'it-IT',
        templateId: '5419b6ffb0d04a076446a9af',
        businessUnitId: '54e2eb320000ff00057d7803',
        href: 'https://it.trustpilot.com/review/www.noicompriamoauto.it',
        height: '28px',
        width: '100%'
      },
      mobile: {
        locale: 'it-IT',
        templateId: '5419b732fbfb950b10de65e5',
        businessUnitId: '54e2eb320000ff00057d7803',
        href: 'https://it.trustpilot.com/review/www.noicompriamoauto.it',
        height: '24px',
        width: '100%'
      }
    },
    'nl-BE': {
      desktop: {
        locale: 'nl-BE',
        templateId: '5419b6ffb0d04a076446a9af',
        businessUnitId: '560a46720000ff000583d31b',
        href: 'https://nl-be.trustpilot.com/review/wijkopenautos.be',
        height: '28px',
        width: '100%'
      },
      mobile: {
        locale: 'nl-BE',
        templateId: '5419b732fbfb950b10de65e5',
        businessUnitId: '560a46720000ff000583d31b',
        href: 'https://nl-be.trustpilot.com/review/wijkopenautos.be',
        height: '24px',
        width: '100%'
      }
    },
    'fr-BE': {
      desktop: {
        locale: 'fr-BE',
        templateId: '5419b6ffb0d04a076446a9af',
        businessUnitId: '598ccb6d0000ff0005a89789',
        href: 'https://fr-be.trustpilot.com/review/www.vendezvotrevoiture.be',
        height: '28px',
        width: '100%'
      },
      mobile: {
        locale: 'fr-BE',
        templateId: '5419b732fbfb950b10de65e5',
        businessUnitId: '598ccb6d0000ff0005a89789',
        href: 'https://fr-be.trustpilot.com/review/www.vendezvotrevoiture.be',
        height: '24px',
        width: '100%'
      }
    },
    'de-AT': {
      desktop: {
        locale: 'de-AT',
        templateId: '5419b6ffb0d04a076446a9af',
        businessUnitId: '5b3265362a5f120001de47de',
        href: 'https://at.trustpilot.com/review/wirkaufendeinauto.at',
        height: '28px',
        width: '100%'
      },
      mobile: {
        locale: 'de-AT',
        templateId: '5419b732fbfb950b10de65e5',
        businessUnitId: '5b3265362a5f120001de47de',
        href: 'https://at.trustpilot.com/review/wirkaufendeinauto.at',
        height: '24px',
        width: '100%'
      }
    },
    'es': {
      desktop: {
        locale: 'es-ES',
        templateId: '5419b6ffb0d04a076446a9af',
        businessUnitId: '55fbe9050000ff0005837144',
        href: 'https://es.trustpilot.com/review/www.compramostucoche.es',
        height: '28px',
        width: '100%'
      },
      mobile: {
        locale: 'es-ES',
        templateId: '5419b732fbfb950b10de65e5',
        businessUnitId: '55fbe9050000ff0005837144',
        href: 'https://es.trustpilot.com/review/www.compramostucoche.es',
        height: '24px',
        width: '100%'
      }
    },
    'fr': {
      desktop: {
        locale: 'fr-FR',
        templateId: '5419b6ffb0d04a076446a9af',
        businessUnitId: '5a0233290000ff0005afd532',
        href: 'https://fr.trustpilot.com/review/vendezvotrevoiture.fr',
        height: '28px',
        width: '100%'
      },
      mobile: {
        locale: 'fr-FR',
        templateId: '5419b732fbfb950b10de65e5',
        businessUnitId: '5a0233290000ff0005afd532',
        href: 'https://fr.trustpilot.com/review/vendezvotrevoiture.fr',
        height: '24px',
        width: '100%'
      }
    },
    'nl': {
      desktop: {
        locale: 'nl-NL',
        templateId: '5419b6ffb0d04a076446a9af',
        businessUnitId: '56a53f160000ff0005880825',
        href: 'https://nl.trustpilot.com/review/wijkopenautos.nl',
        height: '28px',
        width: '100%'
      },
      mobile: {
        locale: 'nl-NL',
        templateId: '5419b732fbfb950b10de65e5',
        businessUnitId: '56a53f160000ff0005880825',
        href: 'https://nl.trustpilot.com/review/wijkopenautos.nl',
        height: '24px',
        width: '100%'
      }
    },
    'sv-SE': {
      desktop: {
        locale: 'sv-SE',
        templateId: '5419b6ffb0d04a076446a9af',
        businessUnitId: '560a49620000ff000583d336',
        href: 'https://se.trustpilot.com/review/vikoperdinbil.se',
        height: '28px',
        width: '100%'
      },
      mobile: {
        locale: 'sv-SE',
        templateId: '5419b732fbfb950b10de65e5',
        businessUnitId: '560a49620000ff000583d336',
        href: 'https://se.trustpilot.com/review/vikoperdinbil.se',
        height: '24px',
        width: '100%'
      }
    },
    'pt': {
      desktop: {
        locale: 'pt-PT',
        templateId: '5419b6ffb0d04a076446a9af',
        businessUnitId: '602438abedd7e00001af84dc',
        href: 'https://pt.trustpilot.com/review/compramososeucarro.pt',
        height: '28px',
        width: '100%'
      },
      mobile: {
        locale: 'pt-PT',
        templateId: '5419b732fbfb950b10de65e5',
        businessUnitId: '602438abedd7e00001af84dc',
        href: 'https://pt.trustpilot.com/review/compramososeucarro.pt',
        height: '24px',
        width: '100%'
      }
    }
  };
  const defaultConfig = localeScreenConfig.de.desktop; // Default to 'de-DE' desktop if not found

  return {
    ...baseConfig,
    ...(((_localeScreenConfig$l = localeScreenConfig[locale]) === null || _localeScreenConfig$l === void 0 ? void 0 : _localeScreenConfig$l[screen]) || defaultConfig)
  };
}