import { useQuery } from '@tanstack/react-query';
import { getComparePriceByType } from '@wkda/funnel-modules';
import useCarleadHashParam from '../useCarleadHashParam';
import { useModulesStore } from '../useModules';
export function useGetConsumerCarleadPrices(priceData, enabled) {
  const {
    consumerModule
  } = useModulesStore();
  const hash = useCarleadHashParam();
  const {
    data
  } = useQuery({
    queryKey: ['aux-prices', hash],
    queryFn: () => consumerModule.getConsumerCarleadPrices(hash),
    enabled: Boolean(hash) && enabled,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false
  });
  const comparePrice = getComparePriceByType(data, 'DAT_VIN_purchase_price_gross');
  return {
    comparePrice: comparePrice && priceData.price && comparePrice < priceData.price ? comparePrice : undefined
  };
}