import { useCallback, useEffect, useMemo } from 'react';
import { getSourceIdByLocale } from '@wkda/funnel-utils';
import { useAppConfiguration } from '../useAppConfiguration';
import { useConsumerSourceId } from '../useDataHooks';
import useConstant from '../useConstant';
import { useModulesStore } from '../useModules';
import useCarleadHashParam from '../useCarleadHashParam';
import { getBookingPageUxFeatures } from './helpers';
import { useMileagePriceTag } from '../../booking/hooks';
import { mileagePriceTagTrackingParams } from './mileagePriceTagTracking';
import { useGetWKDALeadCookie } from '../useGetWKDALeadCookie';

/**
 * Util function returns params depend on application config.
 */
export function getTrackingParamsByConfig(config, consumerSourceId) {
  const {
    appName,
    appVersion,
    sourceId: configSourceId,
    locale
  } = config;
  const sourceId = consumerSourceId || configSourceId || getSourceIdByLocale(locale);
  return {
    appver: appName.replace('@wkda/funnels-', '') + "@" + appVersion,
    appname: appName,
    source: sourceId
  };
}
export function useGetMarketingTrackingService() {
  const {
    tracking
  } = useModulesStore();
  return tracking.marketingTrackingService;
}
const trackLandingWrapper = (config, consumerSourceId, carleadHash, params, service, additionalParams) => {
  const {
    trackLandingPageType,
    appName,
    env
  } = config;

  // booking landing is tracked differently (with external tracking)
  const isBookingLanding = appName.startsWith('@wkda/') && trackLandingPageType === 'booking-handover' && !!carleadHash;
  const configParams = getTrackingParamsByConfig(config, consumerSourceId);
  if (trackLandingPageType) {
    const trackingPayload = {
      pageType: trackLandingPageType,
      ...configParams,
      ...params
    };
    if (isBookingLanding) {
      var _window;
      return service.trackExternal({
        externalType: 'carlead',
        externalId: carleadHash
      }, {
        ...trackingPayload,
        category: 'booking',
        action: 'booking-landing',
        uxFeatures: getBookingPageUxFeatures((_window = window) === null || _window === void 0 || (_window = _window.location) === null || _window === void 0 ? void 0 : _window.href),
        ...mileagePriceTagTrackingParams(additionalParams === null || additionalParams === void 0 ? void 0 : additionalParams.mileagePriceTag)
      });
    } else {
      return service.trackLanding(trackingPayload);
    }
  } else {
    if (env !== 'prod') console.warn('[Warning] - Track landing works only if trackLandingPageType is added to the funnel config');
  }
};
const trackLeadFieldChangedWrapper = _ref => {
  let {
    config,
    consumerSourceId,
    fieldName,
    method,
    service,
    eventData
  } = _ref;
  const configParams = getTrackingParamsByConfig(config, consumerSourceId);
  return service.trackLeadFieldChanged({
    ...configParams,
    leadFieldChanged: fieldName,
    method,
    eventData
  });
};
const trackExternalWrapper = function (config, consumerSourceId, externalType, externalId, category, action) {
  let additionalData = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : {};
  let service = arguments.length > 7 ? arguments[7] : undefined;
  const configParams = getTrackingParamsByConfig(config, consumerSourceId);
  return service.trackExternal({
    externalType,
    externalId
  }, {
    ...configParams,
    action,
    category,
    ...additionalData
  });
};
export function useTrackLandingCallback(params) {
  const config = useAppConfiguration();
  const sourceId = useConsumerSourceId();
  const service = useGetMarketingTrackingService();
  const carleadHash = useCarleadHashParam();
  const mileagePriceTag = useMileagePriceTag();
  return () => trackLandingWrapper(config, sourceId, carleadHash, params, service, {
    mileagePriceTag
  });
}
export function useTrackLanding(params) {
  const config = useAppConfiguration();
  const sourceId = useConsumerSourceId();
  const carleadHash = useCarleadHashParam();
  const configConstant = useConstant(() => config);
  const sourceIdConstant = useConstant(() => sourceId);
  const paramsConstant = useConstant(() => params);
  const carleadHashConstant = useConstant(() => carleadHash);
  const service = useGetMarketingTrackingService();
  const mileagePriceTag = useMileagePriceTag();
  useEffect(() => {
    trackLandingWrapper(configConstant, sourceIdConstant, carleadHashConstant, paramsConstant, service, {
      mileagePriceTag
    });
  }, [configConstant, sourceIdConstant, carleadHashConstant, paramsConstant, service]);
}
export function useTrackLeadFieldChanged() {
  const config = useAppConfiguration();
  const sourceId = useConsumerSourceId();
  const service = useGetMarketingTrackingService();
  return (fieldName, method, eventData) => trackLeadFieldChangedWrapper({
    config,
    consumerSourceId: sourceId,
    fieldName,
    method,
    service,
    eventData
  });
}
export function useTrackLeadFormEvent() {
  const config = useAppConfiguration();
  const sourceId = useConsumerSourceId();
  const service = useGetMarketingTrackingService();
  const configParams = getTrackingParamsByConfig(config, sourceId);
  return (action, method) =>
  // @ts-expect-error leadFieldChanged property is required in trackLeadFieldChanged function but not in actual service.
  service.trackLeadFieldChanged({
    ...configParams,
    action,
    method
  });
}
export function useTrackExternal() {
  const config = useAppConfiguration();
  const sourceId = useConsumerSourceId();
  const service = useGetMarketingTrackingService();
  return useMemo(() => (externalType, externalId, category, action, additionalData) => trackExternalWrapper(config, sourceId, externalType, externalId, category, action, additionalData, service), [config, service, sourceId]);
}
export function useTrackCustomerBanner() {
  const service = useGetMarketingTrackingService();
  const config = useAppConfiguration();
  const configParams = getTrackingParamsByConfig(config);
  const wkdaLead = useGetWKDALeadCookie();
  const CJ_TRACKING_CTA_ATTR = 'data-cj-tracking-button-name';
  function getCJTrackingButtonNameByAttr(element) {
    const ctaName = element.getAttribute(CJ_TRACKING_CTA_ATTR);
    if (!ctaName) {
      console.warn("[CJ Tracking] No button name found on element by " + CJ_TRACKING_CTA_ATTR + " attribute.");
    }
    return ctaName;
  }
  function trackShownEvent(bannerName) {
    return service.trackCustomerBanner({
      externalType: 'carlead',
      externalId: wkdaLead
    }, {
      ...configParams,
      action: 'customer-banner-shown',
      category: 'customer-banner',
      landingPageName: window.location.pathname,
      bannerName
    });
  }
  function trackCTAClickEvent(bannerName, ctaName) {
    service.trackCustomerBanner({
      externalType: 'carlead',
      externalId: wkdaLead
    }, {
      ...configParams,
      action: 'customer-banner-cta-click',
      category: 'customer-banner',
      landingPageName: window.location.pathname,
      bannerName,
      ctaName
    });
  }
  return {
    trackShownEvent,
    trackCTAClickEvent,
    CJ_TRACKING_CTA_ATTR,
    getCJTrackingButtonNameByAttr
  };
}
export function useTrackEvent() {
  const service = useGetMarketingTrackingService();
  const config = useAppConfiguration();
  const sourceId = useConsumerSourceId();
  return useCallback(params => {
    const baseParams = getTrackingParamsByConfig(config, sourceId);
    return service.trackEvent({
      ...baseParams,
      ...params
    }, {
      includeLandingParams: true
    });
  }, [config, service, sourceId]);
}