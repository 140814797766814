import { getQueryParams } from './getQueryParams';
import Cookies from 'universal-cookie';
const QUERY_KEY = 'r';
const COOKIE_KEY = 'referral-code';
const OPTIONS = {
  path: '/',
  maxAge: 3600 * 24 * 365
};
export function setReferFriendEmailCookie(req, res) {
  const queries = getQueryParams(req);
  const referrer = queries[QUERY_KEY];
  const cookies = req ? new Cookies(req.cookies) : new Cookies();
  if (referrer) {
    const referrerSliced = referrer.slice(0, 7);
    if (res) {
      res.cookie(COOKIE_KEY, referrerSliced, OPTIONS);
    } else {
      cookies.set(COOKIE_KEY, referrerSliced, OPTIONS);
    }
  }
}
export function getReferFriendEmailCookie() {
  const cookies = new Cookies();
  let cookie = cookies.get(COOKIE_KEY);

  //TODO: remove after GDPRlib release
  //Context: Our refactored wkda app server is still not in production due to the issue we identified.
  if (!cookie) {
    const queries = getQueryParams();
    cookie = queries[QUERY_KEY];
  }
  return cookie ? cookie : '';
}