/**
 *
 * @param {boolean[]} savedSteps
 * @returns {number}
 */
export function getLastStepFromSaved(savedSteps) {
  const index = savedSteps.lastIndexOf(true);
  let step = 0;
  if (index === -1) {
    step = 0;
  } else if (index === savedSteps.length - 1) {
    step = savedSteps.length - 1;
  } else {
    step = index + 1;
  }
  return step;
}