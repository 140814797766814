import React from 'react';
import { ModalManagerContext } from './ModalManagerProvider';
import { ModalType } from './modalType';

/**
 * @param {Object} props
 * @param {(props: { closeHandler: () => void }) => JSX.Element} props.children
 * @param {string} [props.type]
 * @param {string} props.id
 */
export function Modal(_ref) {
  let {
    children,
    type = ModalType.OTHER,
    id
  } = _ref;
  const [isRegistered, setIsregisterd] = React.useState(false);
  const {
    register,
    shouldRender,
    unregister
  } = React.useContext(ModalManagerContext);
  React.useEffect(() => {
    if (!isRegistered && typeof register === 'function') {
      register(type, id);
      setIsregisterd(true);
    }
  }, [register]);
  return shouldRender(type, id) ? children({
    closeHandler: () => unregister(type, id)
  }) : null;
}