import { useEffect, useContext, useCallback, useRef } from 'react';
import { AnchorStateContext } from './AnchorStateContext';
import { getLastStepFromSaved } from './stepHelpers';
export const ANCHOR = '#s';

/**
 * @typedef {Object} Anchor
 * @property {number} step
 * @property {string} imageId
 */

/**
 * @typedef {import('./AnchorStateContext').Images} Images
 */

const setStepToAnchor = step => {
  const url = new URL(window.location.href);
  window.history.pushState(null, null, url.pathname + url.search + ("" + ANCHOR + (step + 1)));
};
const replaceWithAnchor = step => {
  const url = new URL(window.location.href);
  window.history.replaceState(null, null, url.pathname + url.search + ("" + ANCHOR + (step + 1)));
};

/**
 * @param {boolean[]} savedSteps
 */
const getLastSavedStep = savedSteps => savedSteps.lastIndexOf(true);

/**
 * Parses hash with anchor
 * @param {string} hash
 * @returns {Anchor}
 */
export function parseAnchorHash(hash) {
  const hashValue = hash.replace(ANCHOR, '');
  const [step, imageId] = hashValue.split('_', 2);
  return Object.freeze({
    step: Number(step) - 1,
    imageId: imageId !== null && imageId !== void 0 ? imageId : null
  });
}

/**
 * @param {boolean[]} savedSteps
 * @param {number} step
 * @returns {boolean}
 */
export function isValidStep(savedSteps, step) {
  if (isNaN(step) || step < 0 || step >= savedSteps.length) return false;
  return getLastSavedStep(savedSteps) === -1 ? step === 0 : step <= getLastSavedStep(savedSteps) + 1;
}

/**
 * @param {Images} images
 * @param {Anchor} anchor
 */
export const hasValidImageId = (images, anchor) => {
  var _images$anchor$step;
  return anchor.imageId === null || Boolean((_images$anchor$step = images[anchor.step]) === null || _images$anchor$step === void 0 ? void 0 : _images$anchor$step.has(anchor.imageId));
};

/**
 * @param {boolean[]} savedSteps
 * @param {Images} images
 * @param {Anchor} anchor
 * @returns {boolean}
 */
const isValidAnchor = (savedSteps, images, anchor) => isValidStep(savedSteps, anchor.step) && hasValidImageId(images, anchor);

/**
 * Extracts anchor from location
 * @returns {Anchor}
 */
const getAnchor = () => parseAnchorHash(window.location.hash);

/**
 * Enables anchor navigation for steps
 * @param {Object} params
 * @param {number} params.currentStep
 * @param {React.Dispatch<React.SetStateAction<number>>} params.setStep
 * @param {boolean[]} params.savedSteps
 */
export function useStepAnchors(_ref) {
  let {
    currentStep,
    setStep,
    savedSteps
  } = _ref;
  const isInitialized = useRef(false);
  const {
    images,
    setCurrentImage,
    enableStepAnchor
  } = useContext(AnchorStateContext);
  const applyAnchorData = useCallback(function _applyAnchorData() {
    if (!enableStepAnchor || typeof window === 'undefined') return;
    const anchor = getAnchor();
    if (isValidAnchor(savedSteps, images, anchor)) {
      var _anchor$imageId;
      if (anchor.step !== currentStep) {
        setStep(anchor.step);
      }
      setCurrentImage((_anchor$imageId = anchor.imageId) !== null && _anchor$imageId !== void 0 ? _anchor$imageId : null);
    } else {
      setCurrentImage(null);
      replaceWithAnchor(getLastStepFromSaved(savedSteps));
      setStep(getLastStepFromSaved(savedSteps));
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [currentStep, savedSteps]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(applyAnchorData, []);
  useEffect(() => {
    if (!enableStepAnchor || typeof window === 'undefined') return;
    const anchor = getAnchor();
    if (isInitialized.current && anchor.step !== currentStep) {
      setStepToAnchor(currentStep);
      if (anchor.imageId) {
        setCurrentImage(null);
      }
    }
    isInitialized.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);
  useEffect(() => {
    window.addEventListener('hashchange', applyAnchorData);
    return () => window.removeEventListener('hashchange', applyAnchorData);
  }, [applyAnchorData]);
}