const fieldServiceMap = {
  manufacturer: 'fetchManufacturers',
  mainType: 'fetchMainTypes',
  builtDate: 'fetchBuiltDates',
  bodyType: 'fetchBodyTypes',
  mainTypeDetail: 'fetchMainTypeDetails',
  mainTypeSub: 'fetchMainTypeSub',
  fuelType: 'fetchFuelTypes',
  horsePower: 'fetchHorsePower',
  gearType: 'fetchGearTypes',
  doorCount: 'fetchDoorCount'
};
const machineRootId = 'funnelForm';
const dataFieldsRootId = 'dataFields';
export function generateConfigForNormalMachine(flow, flowArray) {
  const stateNodeConfig = generateStatesConfigForFields(flowArray, flow);
  return stateNodeConfig;
}
function generateStatesConfigForFields(flowArray, flow) {
  const initialItem = flowArray[0];
  const stateConfig = {};
  const rootStateNodeConfig = {
    id: machineRootId,
    initial: (initialItem === null || initialItem === void 0 ? void 0 : initialItem.nodeType) === 'dataField' ? 'dataFields' : initialItem.name
  };
  let dataFields = undefined;
  for (const item of flowArray) {
    let stateNode = {};
    switch (item.nodeType) {
      case 'dataField':
        {
          if (dataFields === undefined) {
            dataFields = {
              initial: item.name,
              id: dataFieldsRootId,
              states: {}
            };
          }
          if (dataFields.states) {
            dataFields.states[item.name] = generateDataNodeConfig(item, flow);
          }
          break;
        }
      case 'questionnaireField':
        {
          stateNode = generateQuestionnaireNodeConfig(item, flow);
          stateConfig[item.name] = stateNode;
          break;
        }
      case 'normalField':
        {
          if (item.name === 'finish') {
            stateConfig[item.name] = {
              entry: [{
                type: 'blurOnEnter'
              }]
            };
          } else {
            stateNode = generateNormalNodeConfig(item, flow);
            stateConfig[item.name] = stateNode;
          }
          break;
        }
    }
  }
  rootStateNodeConfig.states = stateConfig;
  const calculationstates = generateCalculationStates(flowArray);
  if (calculationstates.calculateStateToReset.length > 0) {
    rootStateNodeConfig.states.calculateStateToReset = {
      always: calculationstates.calculateStateToReset
    };
  }
  if (calculationstates.calculateStateToFocus.length > 0) {
    rootStateNodeConfig.states.calculateStateToFocus = {
      always: calculationstates.calculateStateToFocus
    };
  }
  if (dataFields !== undefined) {
    rootStateNodeConfig.states[dataFieldsRootId] = dataFields;
  }
  return rootStateNodeConfig;
}
function generateCalculationStates(flowArray) {
  let calculationStates = {
    calculateStateToFocus: [],
    calculateStateToReset: []
  };
  const resetTransitions = [];
  const alwaysTransitions = [];
  for (const field of flowArray) {
    if (field.name === 'finish') {
      continue;
    }
    if (field.inputFieldType === 'select') {
      resetTransitions.push(generateResetingTransition(field));
    }
    if (field.inputFieldType === 'text') {
      resetTransitions.push(generateResetingTransition(field));
      alwaysTransitions.push(generateFocusTransition(field));
    }
  }
  calculationStates = {
    calculateStateToReset: resetTransitions,
    calculateStateToFocus: alwaysTransitions
  };
  return calculationStates;
}
function generateResetingTransition(field) {
  const {
    nodeType,
    name
  } = field;
  const transition = {
    target: nodeType === 'dataField' ? dataFieldsRootId + "." + name + ".hist" : name,
    guard: {
      type: 'canModifyField',
      params: {
        field: name
      }
    },
    actions: [{
      type: 'resetValuesAndStack',
      params: {
        nodeType: nodeType !== 'dataField' && nodeType !== 'questionnaireField' ? 'none' : nodeType + "s"
      }
    }, {
      type: 'sendSelectAfterReset'
    }]
  };
  return transition;
}
function generateFocusTransition(field) {
  const {
    nodeType,
    name
  } = field;
  const transition = {
    target: nodeType === 'dataField' ? dataFieldsRootId + "." + name + ".hist" : name,
    guard: {
      type: 'canUpdateField',
      params: {
        fieldName: name
      }
    }
  };
  return transition;
}
function generateDataNodeConfig(item, flow) {
  const {
    name,
    next
  } = item;
  const actorToInvoke = fieldServiceMap[name];
  if (actorToInvoke === undefined) {
    throw new Error("could not find requested node " + actorToInvoke);
  }
  const node = {
    initial: 'loading',
    states: {
      loading: {
        invoke: {
          id: actorToInvoke,
          //@ts-expect-error
          src: actorToInvoke,
          input: _ref => {
            let {
              context: {
                values
              }
            } = _ref;
            return {
              values
            };
          },
          onDone: [{
            guard: {
              type: 'hasPreloadedDataForDataField',
              params: {
                fieldName: name
              }
            },
            actions: [{
              type: 'assignOnPreloadAndClear',
              params: {
                fieldName: name
              }
            }, {
              type: 'selectWithPreloaded'
            }],
            target: 'loaded'
          }, {
            target: 'loaded'
          }]
        }
      },
      loaded: {
        always: next.map(target => {
          const isNextOutsideDataGroup = !isNodeInsideDataGroup(flow[target]);
          return {
            guard: {
              type: 'shouldTransitionToNextStateWithValue',
              params: {
                fieldName: name
              }
            },
            target: isNextOutsideDataGroup ? "#" + machineRootId + "." + target : "#" + dataFieldsRootId + "." + target,
            actions: [{
              type: 'updateStack',
              params: {
                fieldName: name,
                nextField: target
              }
            }]
          };
        }),
        on: {
          SELECT: next.map(target => {
            const isNextOutsideDataGroup = !isNodeInsideDataGroup(flow[target]);
            return {
              target: isNextOutsideDataGroup ? "#" + machineRootId + "." + target : "#" + dataFieldsRootId + "." + target,
              guard: {
                type: 'canSelectField',
                params: {
                  fieldName: name
                }
              },
              actions: [{
                type: 'assignDataOnSelect',
                params: {
                  nextField: target
                }
              }, {
                type: 'pushDataLayerEvent'
              }]
            };
          })
        },
        entry: [{
          type: 'focusOnEnter',
          params: {
            fieldName: name
          }
        }],
        exit: [{
          type: 'clearResetTo'
        }]
      },
      hist: {
        type: 'history'
      }
    }
  };
  return node;
}
function generateNormalNodeConfig(item, flow) {
  const {
    name,
    next
  } = item;
  const node = {
    entry: [{
      type: 'focusOnEnter',
      params: {
        fieldName: name
      }
    }],
    exit: [{
      type: 'clearResetTo'
    }],
    always: [{
      guard: {
        type: 'hasPreloadedData',
        params: {
          fieldName: name
        }
      },
      actions: [{
        type: 'assignOnPreloadAndClear',
        params: {
          fieldName: name
        }
      }, {
        type: 'selectWithPreloaded'
      }]
    }, ...next.map(target => {
      return {
        guard: {
          type: 'shouldTransitionToNextStateWithValue',
          params: {
            fieldName: name
          }
        },
        target: generateTargetForNonDataFields(target, isNodeInsideDataGroup(flow[target])),
        actions: [{
          type: 'updateStack',
          params: {
            fieldName: name,
            nextField: target
          }
        }, {
          type: 'pushToDataLayerCache',
          params: {
            fieldName: name
          }
        }]
      };
    })],
    on: {
      SELECT: next.map(target => {
        return {
          target: generateTargetForNonDataFields(target, isNodeInsideDataGroup(flow[target])),
          guard: {
            type: 'canSelectField',
            params: {
              fieldName: name
            }
          },
          actions: [{
            type: 'assignDataOnSelect',
            params: {
              nextField: target
            }
          }, {
            type: 'pushDataLayerEvent'
          }]
        };
      }),
      ...(item.inputFieldType === 'text' ? {
        FILL_INPUT: next.map(target => {
          return {
            target: item.name,
            actions: [{
              type: 'fillInput',
              params: {
                fieldName: item.name,
                nextField: target
              }
            }],
            internal: true
          };
        })
      } : {})
    }
  };
  return node;
}
function generateQuestionnaireNodeConfig(item, flow) {
  const {
    name,
    next
  } = item;
  const node = {
    entry: [{
      type: 'focusOnEnter',
      params: {
        fieldName: name
      }
    }],
    exit: [{
      type: 'clearResetTo'
    }],
    always: [{
      guard: {
        type: 'hasPreloadedData',
        params: {
          fieldName: name
        }
      },
      actions: [{
        type: 'assignOnPreloadAndClear',
        params: {
          fieldName: name
        }
      }, {
        type: 'selectWithPreloaded'
      }]
    }, ...(item.inputFieldType === 'select' ? next.map(target => {
      return {
        target: generateTargetForNonDataFields(target, isNodeInsideDataGroup(flow[target])),
        guard: {
          type: 'stepValueIsFromAnswers',
          params: {
            fromAnswers: item.guards[target],
            fieldName: name
          }
        },
        actions: [{
          type: 'updateStack',
          params: {
            fieldName: name,
            nextField: target
          }
        }, {
          type: 'pushQuestionToDataLayerCache',
          params: {
            fieldName: name,
            nextField: target,
            guards: item.guards
          }
        }]
      };
    }) : [])],
    on: {
      SELECT: [...next.map(target => {
        return {
          target: generateTargetForNonDataFields(target, isNodeInsideDataGroup(flow[target])),
          guard: item.inputFieldType === 'select' ? {
            type: 'canSelectQuestionnaireField',
            params: {
              fromAnswers: item.guards[target],
              fieldName: name
            }
          } : {
            type: 'canSelectField',
            params: {
              fieldName: name
            }
          },
          actions: [{
            type: 'assignDataOnSelect',
            params: {
              nextField: target
            }
          }, {
            type: 'pushQuestionnaireDataLayerEvent',
            params: {
              nextField: target,
              guards: item.inputFieldType === 'select' ? item.guards : undefined
            }
          }, {
            type: 'syncQuestionnaireCookies',
            params: {
              fieldName: name,
              nextField: target,
              cookieName: item.cookieName
            }
          }]
        };
      })],
      ...(item.inputFieldType === 'text' ? {
        FILL_INPUT: next.map(target => {
          return {
            target: item.name,
            actions: [{
              type: 'fillInput',
              params: {
                fieldName: item.name,
                nextField: target
              }
            }],
            internal: true
          };
        })
      } : {})
    }
  };
  return node;
}
export function flowToArray(flow) {
  let initialKey = getFlowInitialKey(flow);
  const fields = [...GraphBFS(flow, initialKey)].map(item => item[0]);
  return fields;
}
export function* getIterableFlow(flow) {
  let initialKey = getFlowInitialKey(flow);
  for (const item of GraphBFS(flow, initialKey)) {
    yield item;
  }
}
export function createFieldCategories(flow) {
  const result = {
    dataField: [],
    normalField: [],
    questionnaireField: [],
    noDataFields: []
  };
  for (const item of flow) {
    const {
      nodeType,
      name
    } = item;
    if (!result[nodeType]) {
      result[nodeType] = [name];
    } else {
      var _result$nodeType;
      result === null || result === void 0 || (_result$nodeType = result[nodeType]) === null || _result$nodeType === void 0 || _result$nodeType.push(name);
    }
    if (nodeType !== 'dataField') {
      result.noDataFields.push(name);
    }
  }
  return result;
}
export function transformInitialFlow(_ref2) {
  let {
    initial,
    ...rest
  } = _ref2;
  if (initial === undefined) {
    return rest;
  }
  return {
    ...rest,
    [initial.name]: initial
  };
}
function* GraphBFS(graph, initialKey) {
  const queue = [initialKey];
  const visited = {};
  visited[initialKey] = true;
  let currentVertex;
  let previousVertex;
  while (queue.length) {
    var _previousVertex;
    currentVertex = queue.shift();
    if (currentVertex === undefined) {
      continue;
    }
    const item = graph[currentVertex];
    if (item === undefined) {
      continue;
    }
    const previousItem = graph[(_previousVertex = previousVertex) !== null && _previousVertex !== void 0 ? _previousVertex : ''];
    yield [item, previousItem];
    previousVertex = currentVertex;
    for (const neighbor of (_item$next = item.next) !== null && _item$next !== void 0 ? _item$next : []) {
      var _item$next;
      if (!visited[neighbor]) {
        visited[neighbor] = true;
        queue.push(neighbor);
      }
    }
  }
}
function isNodeInsideDataGroup(item) {
  return (item === null || item === void 0 ? void 0 : item.nodeType) === 'dataField';
}
function generateTargetForNonDataFields(target, isDataField) {
  return isDataField ? "#" + dataFieldsRootId + "." + target : "" + target;
}
function findInitialItemKey(graph) {
  // Create a set of all item names
  const allItems = new Set(Object.keys(graph));

  // Single pass through the graph
  for (const item of Object.values(graph)) {
    if (Array.isArray(item.next)) {
      item.next.forEach(nextItem => {
        allItems.delete(nextItem);
      });
    }
  }

  // The remaining item in allItems is the initial item
  if (allItems.size === 1) {
    var _allItems$values$next;
    return (_allItems$values$next = allItems.values().next().value) !== null && _allItems$values$next !== void 0 ? _allItems$values$next : null;
  }

  // If no initial item is found or multiple initial items, return null or throw an error
  return null; // or throw new Error("No unique initial item found");
}
function getFlowInitialKey(flow) {
  let initialKey = null;
  if (flow.initial) {
    initialKey = 'initial';
  } else {
    initialKey = findInitialItemKey(flow);
    if (!initialKey) {
      throw new Error('Flow object must contain an initial key');
    }
  }
  return initialKey;
}