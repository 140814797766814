import consumerCarleadStatuses from './consumerCarleadStatuses';
export const bannerNames = {
  L2A: 'l2a',
  ONSITE_EXPIRED: 'onsite-expired',
  ONSITE_VALID: 'onsite-valid',
  NO_SHOW: 'no-show',
  APPOINTMENT_REMINDER: 'appointment-reminder',
  CANCELLED_APPOINTMENT: 'cancelled-appointment'
};
export function getBannerName(consumerCarlead, appoinmentStatus) {
  const {
    L2A,
    NO_SHOW,
    APPOINTMENT_REMINDER,
    ONSITE_EXPIRED,
    ONSITE_VALID
  } = bannerNames;
  if (appoinmentStatus === 'cancelled') return bannerNames.CANCELLED_APPOINTMENT;
  let bannerName = L2A;
  const {
    branch,
    appointment,
    onsiteDateTime,
    statusId,
    priceExpiresInDays
  } = consumerCarlead;
  if (onsiteDateTime) {
    if (appointment !== null) {
      const startDate = new Date(appointment.start);
      const missedAppointment = Date.now() > startDate.getTime();
      bannerName = missedAppointment ? NO_SHOW : APPOINTMENT_REMINDER;
    } else {
      bannerName = priceExpiresInDays ? ONSITE_VALID : ONSITE_EXPIRED;
    }
  } else if (branch && statusId !== consumerCarleadStatuses.ONSITE_PRICE_GIVEN && appointment) {
    const startDate = new Date(appointment.start);
    bannerName = Date.now() > startDate.getTime() ? NO_SHOW : APPOINTMENT_REMINDER;
  }
  return bannerName;
}