/**@type {import("./types").IEmailSuggestionConfiguration} */
export const emailSuggestionConfiguration = {
  domainThreshold: 2,
  secondLevelThreshold: 2,
  topLevelThreshold: 1.5,
  listDomains: ['gmail.com', 'hotmail.com', 'aol.com', 'aliceadsl.fr', 'arcor.de', 'berlin.de', 'laposte.net', 'wanadoo.fr', 'bluewin.ch', 'blueyonder.co.uk', 'bol.com.br', 'btinternet.com', 'buziaczek.pl', 'click21.com.br', 'icloud.com', 'home.nl', 'exdy.tk', 'fastwebnet.it', 'free.fr', 'freenet.de', 'gazeta.pl', 'globomail.com', 'ig.com.br', 'inwind.it', 'kabelmail.de', 'libero.it', 'me.com', 'mixmail.com', 'muster.de', 'mustermann.de', 'netcologne.de', 'neuf.fr', 'oi.com.br', 'op.pl', 'orange.fr', 'p4marketing.de', 'reichstein-architekten.de', 'rocketmail.com', 'salaw.de', 'schwarzenbach.net', 'sense-line.de', 'sfef.org', 'sfr.fr', 'sier-kosmetik.de', 'sofort-mail.de', 'superonline.com', 'talk21.com', 'talktalk.net', 'telefonica.net', 'test.de', 'tin.it', 'tlen.pl', 'trash-mail.com', 'uol.com.br', 'versanet.de', 'virgilio.it', 'voila.fr', 'vp.pl', 'web.de', 'webmail.de', 'wp.pl', 'zdf.de', 'ziggo.nl', 'sapo.pt'],
  listSecondLevelDomains: ['gmail', 'googlemail', 'yahoo', 'hotmail', 'aol', 'alice', 'outlook', 'arcor', 'bluewin', 'garanti', 'gmx', 'interia', 'live', 'mail', 'msn', 'mynet', 'ntlworld', 'telefonica', 'laposte', 't-online', 'orange', 'online', 'o2online', 'terra', 'tiscali', 'virgilio', 'windowslive', 'icloud', 'sapo'],
  fixedTopLevelDomains: {
    'gmail': 'com',
    't-online': 'de',
    'laposte': 'net',
    'wanadoo': 'fr',
    'icloud': 'com',
    'me': 'com',
    'alice': 'it',
    'virgilio': 'it',
    'telefonica': 'net'
  },
  listTopLevelDomainsByLocales: {
    'de': ['de', 'com'],
    'es': ['es', 'cat', 'com'],
    'nl': ['nl', 'com'],
    'it': ['it', 'com'],
    'fr': ['fr', 'com'],
    'pl': ['pl', 'com'],
    'pt': ['pt', 'com'],
    'da': ['dk', 'com'],
    'de-AT': ['at', 'com'],
    'nl-BE': ['be', 'com'],
    'fr-BE': ['be', 'com'],
    'sv-SE': ['se', 'com']
  },
  listPrioritizedTopLevelDomains: ['com', 'net', 'de', 'fr', 'es', 'it', 'nl', 'be', 'at', 'se', 'pl', 'pt'],
  listTopLevelDomains: ['com', 'com.au', 'com.tw', 'com.br', 'ca', 'co.nz', 'co.uk', 'de', 'fr', 'it', 'ru', 'net', 'org', 'edu', 'gov', 'jp', 'nl', 'kr', 'se', 'pt', 'eu', 'ie', 'co.il', 'us', 'at', 'be', 'dk', 'hk', 'es', 'gr', 'ch', 'no', 'cz', 'in', 'net', 'net.au', 'info', 'biz', 'co.jp', 'sg', 'hu', 'cat', 'uk', 'lu', 'sk']
};