import { useQuery } from '@tanstack/react-query';
import { useAppConfiguration } from '../../hooks/useAppConfiguration';
import useCarleadHashParam from '../../hooks/useCarleadHashParam';
import { useGetConsumerCarlead } from '../../hooks/useDataHooks';
import { useModulesStore } from '../../hooks/useModules';
export function useLastAppointment(carleadHash) {
  const {
    appointmentModule
  } = useModulesStore();
  const {
    data
  } = useQuery({
    queryKey: ['lastAppointment', carleadHash],
    queryFn: () => appointmentModule.getLastAppointments(carleadHash),
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });
  return data;
}
export function useLastAppointments() {
  const {
    locale
  } = useAppConfiguration();
  const carleadHash = useCarleadHashParam();
  const {
    data: consumerCarlead
  } = useGetConsumerCarlead();
  const data = useLastAppointment(carleadHash);
  return {
    locale,
    appointmentStartDate: data === null || data === void 0 ? void 0 : data.slot.startTime,
    car: consumerCarlead.car.manufacturer + " " + consumerCarlead.car.modelDescription
  };
}