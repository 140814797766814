export function mileagePriceTagTrackingParams(mileagePriceTag) {
  const params = {};
  const {
    showLowMileageTag,
    showHighMileageTag,
    lowMileageTagShown,
    highMileageTagShown,
    carMileage,
    carYear,
    carYearThresholdLow,
    carYearThresholdHigh
  } = mileagePriceTag;

  // if no config or main fields do not track
  const noConfig = !showLowMileageTag && !showHighMileageTag;
  const noCarData = !carMileage || !carYear;
  if (noConfig || noCarData) return params;
  params.mileageTagShown = lowMileageTagShown || highMileageTagShown;
  if (lowMileageTagShown) params.mileageTagShownType = 'low';
  if (highMileageTagShown) params.mileageTagShownType = 'high';

  // track only if config enabled
  if (showLowMileageTag) {
    params.lowMileageTag = {
      mileageThreshold: carYearThresholdLow,
      mileageDelta: lowMileageTagShown ? carYearThresholdLow - carMileage : null,
      mileageDeltaPercentage: lowMileageTagShown ? Math.round(carMileage / carYearThresholdLow * 100) : null
    };
  }

  // track only if config enabled
  if (showHighMileageTag) {
    params.highMileageTag = {
      mileageThreshold: carYearThresholdHigh,
      mileageDelta: highMileageTagShown ? carMileage - carYearThresholdHigh : null,
      mileageDeltaPercentage: highMileageTagShown ? Math.round(carMileage / carYearThresholdHigh * 100) : null
    };
  }
  return {
    ...params,
    carMileage,
    carYear
  };
}