export function createQuestionnaires(_ref) {
  let {
    config,
    mappers
  } = _ref;
  return {
    transform
  };
  function transform(values) {
    return transformQuestionnairesToDTOPayload(createQuestionnairesMapper({
      config,
      mappers
    })({
      values
    }));
  }
}
function createQuestionnairesMapper(_ref2) {
  let {
    config,
    mappers
  } = _ref2;
  function questionnairesMapper(_ref3) {
    let {
      values
    } = _ref3;
    let result = {};
    for (const mapperFn of mappers.toReversed()) {
      result = {
        ...result,
        ...mapperFn({
          values,
          config
        })
      };
    }
    return result;
  }
  return questionnairesMapper;
}
function transformQuestionnairesToDTOPayload(mapperResult) {
  const parsed = Object.entries(mapperResult).flatMap(_ref4 => {
    let [key, value] = _ref4;
    return typeof value === 'string' ? createQuestionnaireItemObject(key, value) : value;
  });
  const transformed = Object.groupBy(parsed, item => {
    return item.type;
  });
  let result = [];
  for (const [type, values] of Object.entries(transformed)) {
    if (!values) {
      continue;
    }
    result.push({
      type,
      questions: values.map(item => {
        return {
          question: item.question,
          answer: item.answer
        };
      })
    });
  }
  return result;
}
function createQuestionnaireItemObject(question, answer) {
  let type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'customerSurvey';
  return {
    question,
    answer,
    type
  };
}