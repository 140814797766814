import pick from 'lodash-es/pick';
import { fromPromise } from 'xstate';
export function getFetcherActor(queryClient, languageParams) {
  return function getFetchActor(pickParams, fieldName, fetchFn) {
    return fromPromise(function fetch(_ref) {
      let {
        input: {
          values
        }
      } = _ref;
      const params = pick(values, pickParams);
      return queryClient.fetchQuery({
        queryKey: [fieldName, params],
        queryFn: () => {
          return fetchFn({
            ...params,
            ...languageParams
          });
        }
      });
    });
  };
}