import { useEffect } from 'react';
import { useFormState } from 'react-final-form';
import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
export const useCarleadCreationFormStore = create(devtools(persist((set, get) => ({
  values: {},
  labels: {},
  setValues: values => set(() => ({
    values: values
  })),
  setLabel: (field, label) => set(() => ({
    labels: {
      ...get().labels,
      [field]: label
    }
  }))
}), {
  name: 'carleadCreationForm'
})));
export function SyncFormValuesGlobalStore() {
  const {
    values
  } = useFormState({
    subscription: {
      values: true
    }
  });
  const setValues = useCarleadCreationFormStore(state => state.setValues);
  useEffect(() => {
    if (setValues && values) setValues(values);
  }, [values, setValues]);
  return null;
}