import { createMarketingProperties, createMarketingPropertiesDictionary } from './createMarketingProperties';
import { formNames as _formNames } from './formNames';
import { createInitialValues } from './initialValues';
import { createValidation } from './validation';
export function createCarleadCreationForm(_ref) {
  let {
    config,
    errorMessages,
    payloadMapper,
    defaultValuesSelector,
    createSteps,
    questionnaires,
    formNames = _formNames,
    partialPayloadMappers = [],
    marketingPropertiesMappers = [],
    createCustomValidators = [],
    othersValidators = {},
    defaultValues = {},
    marketingProperties = createMarketingPropertiesDictionary(),
    lifecycleHooks = {}
  } = _ref;
  const {
    validate
  } = createValidation({
    locale: config.locale,
    errors: errorMessages,
    createCustomValidators,
    othersValidators
  });
  const marketingPropertiesMapper = createMarketingProperties(config, marketingProperties);
  const generateInitialValues = createInitialValues({
    defaultValuesSelector,
    defaultValues
  });
  return {
    validateStep,
    validateAllSteps,
    validateFields,
    generateInitialValues,
    mapValuesToPayload,
    getStepFields,
    lifecycleHooks: initializeLifecycleHooks(lifecycleHooks),
    get steps() {
      return getSteps();
    }
  };
  function getStepFields(step) {
    return getSteps()[step];
  }
  function getSteps() {
    return composeCreateStepFunctions(createSteps, formNames)([[]], formNames);
  }
  function validateStep(step, values) {
    return validate(getSteps().slice(0, step + 1).flat(), values);
  }
  function validateAllSteps(values) {
    return getSteps().map(step => {
      return validate(step, values);
    });
  }
  function validateFields(fields, values) {
    return validate(fields, values);
  }
  function mapValuesToPayload(initialPayload, values) {
    const marketingProperties = marketingPropertiesMapper(marketingPropertiesMappers, values);
    return payloadMapper({
      config,
      initialPayload,
      values,
      marketingProperties,
      partialMappers: partialPayloadMappers,
      questionnaires: questionnaires.transform(values)
    });
  }
}

//TODO: evaluate if it's better to move to another place, is weird
//having it defined here without any implementation
const defaultHooks = {
  onStepSubmitSuccess() {},
  onValidationError() {}
};
function initializeLifecycleHooks(hooks) {
  return {
    ...defaultHooks,
    ...hooks
  };
}
function composeCreateStepFunctions(stepFunctions, formNames) {
  const createStep = function createStep(_steps) {
    return stepFunctions.reduceRight((accumulator, fn) => {
      return fn(accumulator, formNames);
    }, _steps);
  };
  return createStep;
}