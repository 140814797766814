import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { isRiskFree, getOnlinePriceData, getCarSelector, getCarPropertyByIdSelector, CAR_PROPERTIES } from '@wkda/funnel-modules';
import { useGetConsumerCarlead } from '../../hooks/useDataHooks';
import { useBookingAppConfiguration } from '../../hooks/useBookingAppConfiguration';
import { useModulesStore } from '../../hooks/useModules';
import { useGetConsumerCarleadPrices } from '../../hooks/useDataHooks/useGetConsumerCarleadPrices';
const HANDOVER_PRICE_EXPIRED_SUFFIX = 'i';
const isExpiredPriceHandoverPage = urlParams => {
  var _urlParams$profileSuf;
  return ((_urlParams$profileSuf = urlParams.profileSuffix) === null || _urlParams$profileSuf === void 0 ? void 0 : _urlParams$profileSuf.toLocaleLowerCase()) === HANDOVER_PRICE_EXPIRED_SUFFIX;
};
export function useHandoverProps() {
  var _getCarPropertyByIdSe, _getCarPropertyByIdSe2;
  const {
    data: consumerCarlead,
    isLoading
  } = useGetConsumerCarlead();
  const params = useParams();
  const config = useBookingAppConfiguration();
  const {
    inspectionModule
  } = useModulesStore();
  const isSelfEvalutation = ((_getCarPropertyByIdSe = getCarPropertyByIdSelector(consumerCarlead, CAR_PROPERTIES.ONLINE_SELF_EVALUATION)) === null || _getCarPropertyByIdSe === void 0 ? void 0 : _getCarPropertyByIdSe.value) === 'online-self-evaluation';
  const isAssumption = ((_getCarPropertyByIdSe2 = getCarPropertyByIdSelector(consumerCarlead, CAR_PROPERTIES.SELF_EVALUATION_ASSUMPTION)) === null || _getCarPropertyByIdSe2 === void 0 ? void 0 : _getCarPropertyByIdSe2.value) === 'true';
  const {
    data
  } = useQuery({
    queryKey: ['reevaluationApplicable', consumerCarlead === null || consumerCarlead === void 0 ? void 0 : consumerCarlead.hash],
    queryFn: () => inspectionModule.getReevaluationApplicability(consumerCarlead === null || consumerCarlead === void 0 ? void 0 : consumerCarlead.hash),
    enabled: Boolean(config.selfReevaluationLink) && !isLoading,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });
  const car = getCarSelector(consumerCarlead);
  const priceData = getOnlinePriceData(consumerCarlead);
  const comparePriceData = useGetConsumerCarleadPrices(priceData, Boolean(config.handoverPriceComparison) && !isLoading);
  return {
    isRiskFree: isRiskFree(consumerCarlead),
    hasValidBid: consumerCarlead === null || consumerCarlead === void 0 ? void 0 : consumerCarlead.hasValidBid,
    priceExpired: consumerCarlead === null || consumerCarlead === void 0 ? void 0 : consumerCarlead.priceExpired,
    priceExpiresInDays: (consumerCarlead === null || consumerCarlead === void 0 ? void 0 : consumerCarlead.priceExpiresInDays) || 0,
    isExpiredPriceHandoverPage: isExpiredPriceHandoverPage(params),
    selfReevaluationLink: config.selfReevaluationLink,
    isReevaluationApplicable: Boolean(data === null || data === void 0 ? void 0 : data.isReevaluationApplicable),
    hash: consumerCarlead === null || consumerCarlead === void 0 ? void 0 : consumerCarlead.hash,
    car,
    isLoading,
    isSelfEvalutation,
    isAssumption,
    ...priceData,
    ...comparePriceData
  };
}