import { jwtDecode } from 'jwt-decode';
const RESTRICTED = 'restricted';
export const TOKEN_STATUSES = {
  noToken: 'noToken',
  notVerified: 'noVerified',
  tokenExpired: 'TokenExpired',
  tokenValid: 'tokenValid'
};

/**
 * This number will be subtracted from the token exp date to have safe margin to refresh token.
 * @type {number}
 */
const EXPIRATION_SAFE_MARGIN_SECS = 60;
export class AuthManager {
  static _getTokenData(token) {
    return jwtDecode(token);
  }
  static getTokenState(token) {
    if (!token) {
      return TOKEN_STATUSES.noToken;
    }
    const {
      exp,
      scope
    } = AuthManager._getTokenData(token);
    const isRestricted = Array.isArray(scope) && scope.includes(RESTRICTED);
    // now - 1 minute > expiration date
    const hasExpired = exp ? Date.now() / 1000 > exp - EXPIRATION_SAFE_MARGIN_SECS : true;
    if (isRestricted) {
      return TOKEN_STATUSES.notVerified;
    }
    if (hasExpired) {
      return TOKEN_STATUSES.tokenExpired;
    }
    return TOKEN_STATUSES.tokenValid;
  }
  constructor(localStorageService) {
    let storageKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'customer_authentication';
    this._localStorageService = localStorageService;
    this._storageKey = storageKey;
  }
  getDetailsFromToken(token) {
    if (!token) {
      return {
        email: '',
        accountId: '',
        tokenState: this.getTokenState(token),
        token: ''
      };
    }
    const {
      accountId,
      user_name,
      authentication_type
    } = AuthManager._getTokenData(token);
    return {
      accountId,
      email: user_name,
      tokenState: this.getTokenState(token),
      authType: authentication_type,
      token
    };
  }

  /**
   * Get token state
   * @param token
   * @return {string}
   */
  // eslint-disable-next-line class-methods-use-this
  getTokenState(token) {
    return AuthManager.getTokenState(token);
  }

  /**
   * Get customer authentication object
   */
  getCustomerAuthentication() {
    return JSON.parse(this._localStorageService.getItem(this._storageKey) || '{}');
  }

  /**
   * Get refresh token
   * @return {string|null}
   */
  getRefreshToken() {
    return this.getCustomerAuthentication().refresh_token || null;
  }

  /**
   * Get access token
   * @return {string|null}
   */
  getAccessToken() {
    return this.getCustomerAuthentication().access_token || null;
  }

  /**
   * Remove auth
   * @return {void}
   */
  removeAuth() {
    this._localStorageService.removeItem(this._storageKey);
  }

  /**
   * Override local storage item
   * @param data
   */
  writeToCustomerAuth() {
    let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    this._localStorageService.setItem(this._storageKey, JSON.stringify(data));
  }

  /**
   * Merge authentication data in local storage
   * @param data
   * @param authData
   */
  mergeCustomerAuth() {
    let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    let authData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.getCustomerAuthentication();
    const newData = Object.assign({}, authData, data);
    this.writeToCustomerAuth(newData);
  }
}