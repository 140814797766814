import { useEffect } from 'react';
import { useField } from 'react-final-form';
import { useStateMachineForDataFields } from './useStateMachineForFields';
import { useCarleadConfiguration } from '../../hooks/useCarleadCreationConfiguration';

/**
 * Autoselect the first item of the array of options
 **/
export function useAutoSelectForDataFields(name, data) {
  const {
    input
  } = useField(name, {
    subscription: {}
  });
  const {
    isInCurrentField,
    sendSelect
  } = useStateMachineForDataFields({
    name
  });
  const {
    autoSelectCallback
  } = useCarleadConfiguration();
  useEffect(() => {
    if (data.length === 1 && isInCurrentField) {
      // For some reason without this timeout the `bodyType` input
      // is not updated on step 2 during the form data restoration
      // from the local storage.
      setTimeout(() => {
        const item = data[0]; // I already know that there is at least one item;
        input.onChange(item === null || item === void 0 ? void 0 : item.value);
        sendSelect(item.value, 'autofill');

        // callback since this action trigger onchange event
        //when data changes and only have one item
        if (typeof autoSelectCallback === 'function') {
          autoSelectCallback(name);
        }
      }, 0);
    }
  }, [sendSelect, input, isInCurrentField, data, autoSelectCallback, name]);
}
export function useSyncValues(name) {
  const {
    dataValues
  } = useStateMachineForDataFields({
    name
  });
  const serviceValue = dataValues[name];
  const {
    input: {
      onChange: fieldOnChange
    }
  } = useField(name, {
    subscription: {}
  });
  useEffect(() => {
    fieldOnChange(serviceValue);
  }, [serviceValue, name, fieldOnChange]);
}