import { Money } from '@wkda/money';
export const CURRENCY_SYMBOLS = {
  EUR: '€',
  PLN: 'zł',
  DKK: 'kr',
  SEK: 'kr'
};
export const INTL_LOCALE_MAP = {
  'de': 'de',
  'es': 'de',
  'nl': 'nl',
  'it': 'it',
  'fr': 'fr',
  'pl': 'pl',
  'pt': 'pt',
  'da': 'da',
  'de-AT': 'de',
  'nl-BE': 'nl-BE',
  'fr-BE': 'fr-BE',
  'sv-SE': 'sv-SE'
};
export function getCurrencySymbol(currency) {
  return CURRENCY_SYMBOLS[currency];
}
export function formatNumberTwoFractions(locale) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  let intlLocale = INTL_LOCALE_MAP[locale];
  const numberFormat = new Intl.NumberFormat(intlLocale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    style: 'decimal',
    ...options
  });
  return numberFormat;
}
export function formatMoneyFixed(locale, value) {
  return new Money(INTL_LOCALE_MAP[locale], value).toString();
}
export function formatThousand(locale, value) {
  if (value === null || value === '') {
    return '';
  }
  return formatNumberTwoFractions(locale).format(Number(value));
}