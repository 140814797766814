import { formNames } from '@wkda/carlead-creation-form';
import { DSBFieldsMap } from './formCache/DSBFieldsMap';
const pairs = [[formNames.manufacturer, DSBFieldsMap.manufacturer], [formNames.mainType, DSBFieldsMap.model], [formNames.builtDate, DSBFieldsMap.builtYear], [formNames.bodyType, DSBFieldsMap.bodyType], [formNames.fuelType, DSBFieldsMap.fuelType], [formNames.horsePower, DSBFieldsMap.horsePower], [formNames.gearType, DSBFieldsMap.gearType], [formNames.doorCount, DSBFieldsMap.doorCount], [formNames.mainTypeDetail, DSBFieldsMap.modelDetails], [formNames.mainTypeSub, DSBFieldsMap.modelDetailsSub], [formNames.mileage, DSBFieldsMap.mileage], [formNames.exactMileage, DSBFieldsMap.exactMileage], [formNames.location, DSBFieldsMap.zipCode], [formNames.email, DSBFieldsMap.email], [formNames.licensePlate, DSBFieldsMap.licensePlate]];
function generateMapFromPairs(pairs) {
  const map = pairs.reduce((acc, _ref) => {
    let [fieldSF, fieldDSB] = _ref;
    return {
      DSBToSFMap: {
        ...acc.DSBToSFMap,
        [fieldDSB]: fieldSF
      },
      SFToDSBMap: {
        ...acc.SFToDSBMap,
        [fieldSF]: fieldDSB
      }
    };
  }, {
    SFToDSBMap: {},
    DSBToSFMap: {}
  });
  return {
    toDSB(key) {
      return map.SFToDSBMap[key];
    },
    fromDSB(key) {
      return map.DSBToSFMap[key];
    }
  };
}
export const CarleadFieldsMap = generateMapFromPairs(pairs);