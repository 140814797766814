export function SELECT(fieldName, selected, selectionType) {
  return {
    type: 'SELECT',
    fieldName,
    selected,
    selectionType
  };
}
export function FILL_INPUT(fieldName, selected, selectionType) {
  return {
    type: 'FILL_INPUT',
    fieldName,
    selected,
    selectionType
  };
}
export function FOCUS_INPUT(fieldName) {
  return {
    type: 'FOCUS_INPUT',
    fieldName
  };
}
export function BLUR_INPUT(fieldName) {
  return {
    type: 'BLUR_INPUT',
    fieldName
  };
}
export function VALIDATE_MAPPING(licensePlateSearchData) {
  return {
    type: 'VALIDATE_MAPPING',
    licensePlateSearchData
  };
}
export function COMMIT_VALUES() {
  return {
    type: 'COMMIT_VALUES'
  };
}
export function isFillInputEvent(evt) {
  return evt.type === 'FILL_INPUT';
}
export function isSelectEvent(evt) {
  return evt.type === 'SELECT';
}
export function isFocusInputEvent(evt) {
  return evt.type === 'FOCUS_INPUT';
}