import { useMutation } from '@tanstack/react-query';
import { useModulesStore } from '@wkda/funnel-components';
import pick from 'lodash-es/pick';
import { useParams } from 'react-router-dom';
import { waitFor } from 'xstate';
import { useCarleadConfiguration } from './useCarleadCreationConfiguration';
import { useCarleadCjTracking } from './useCarleadCjTracking';
const LICENSE_PLATE_NOT_RECOGNIZED = 'licensePlateNotRecognized';
const LICENSE_PLATE = 'licensePlate';
const LICENSE_PLATE_FALLBACK = 'fallbackType';
export function useCreateCarleadChainedRequest(requestChain) {
  const requestMutation = useMutation({
    mutationKey: ['createConsumerCarlead'],
    mutationFn: composeRequestChain(requestChain)
  });
  return requestMutation.mutateAsync;
}
export function useCreateConsumerCarleadRequestChain() {
  const {
    carleadModule: {
      createCarlead
    }
  } = useModulesStore();
  const createConsumerCarlead = async function createConsumerCarlead(_ref) {
    let {
      requestPayload: payload,
      extraParams,
      response
    } = _ref;
    if (!extraParams.isLastStep) {
      return response;
    }
    return createCarlead(payload);
  };
  return createConsumerCarlead;
}
export function useCreateConsumerCarleadWithLicensePlateRequestChain() {
  const {
    consumerModule: {
      createConsumerCarleadLicensePlate
    },
    cidModule
  } = useModulesStore();
  const {
    invalidLicensePlateFallbackPath = ''
  } = useCarleadConfiguration();
  const {
    trackError
  } = useCarleadCjTracking();
  const createConsumerCarlead = async function createConsumerCarlead(_ref2) {
    let {
      requestPayload: payload,
      extraParams,
      response
    } = _ref2;
    if (!extraParams.isLastStep) {
      return response;
    }
    try {
      const res = await createConsumerCarleadLicensePlate(payload);
      await cidModule.trackCidEvent({
        carleadHash: res.hash,
        event: 'LEAD'
      });
      return res;
    } catch (error) {
      // Track invalid LP error
      trackError(LICENSE_PLATE_NOT_RECOGNIZED, 'INVALID');
      redirectOnLicensePlateError(invalidLicensePlateFallbackPath);
      return Promise.reject({});
    }
  };
  return createConsumerCarlead;
}
export function useLicensePlateSearchChainRequest() {
  const {
    licensePlateMappingModule: {
      searchCarDataMapping
    },
    carleadModule: {
      createCarlead
    },
    cjTrackingModule: {
      cjTracking
    }
  } = useModulesStore();
  const {
    invalidLicensePlateFallbackPath
  } = useCarleadConfiguration();
  const {
    dlp
  } = useParams();
  return async function (_ref3) {
    let {
      requestPayload: payload,
      extraParams
    } = _ref3;
    const {
      machineService,
      formApi,
      carleadForm
    } = extraParams;
    const {
      values,
      previousValues
    } = machineService.getSnapshot().context;
    if ((values === null || values === void 0 ? void 0 : values.licensePlate) !== previousValues.licensePlate) {
      let mapping = undefined;
      try {
        mapping = await searchCarDataMapping(values.licensePlate);
      } catch (error) {
        if (!invalidLicensePlateFallbackPath) {
          console.info("Missing invalidLicensePlateFallbackPath in app configuration");
        } else {
          redirectOnLicensePlateError(invalidLicensePlateFallbackPath);
        }
      }
      const licensePlateSearchValues = parseLicensePlateSearchToValues(mapping);
      const {
        getState,
        reset
      } = formApi;
      machineService.send({
        type: 'VALIDATE_MAPPING',
        //@ts-expect-error
        licensePlateSearchData: licensePlateSearchValues
      });
      machineService.send({
        type: 'COMMIT_VALUES'
      });
      await waitFor(machineService, state => {
        return state.context.validationStatus === 'valid' || state.context.validationStatus === 'error' || state.context.validationStatus === 'invalid';
      });
      const context = machineService.getSnapshot().context;
      const newValues = {
        ...getState().values,
        ...pick(context.values, context.dataFields)
      };
      reset(newValues);
      if (context.validationStatus === 'invalid') {
        return Promise.reject({});
      }
      if (context.validationStatus === 'valid') {
        return createCarlead(carleadForm.mapValuesToPayload({}, {
          ...context.values,
          ...licensePlateSearchValues
        }));
      }
    }
    const invalidMappingFields = machineService.getSnapshot().context.invalidMappingFields;
    const carlead = await createCarlead(payload);
    try {
      cjTracking({
        type: 'cfe',
        appname: 'wkda',
        action: 'dropdowns-visible',
        userAgent: navigator.userAgent,
        hash: carlead.hash,
        category: 'sweden-dsb-lp',
        dlp: dlp,
        step: Array.isArray(invalidMappingFields) ? invalidMappingFields.join(',') : '',
        source: '1'
      });
    } catch (error) {}
    return carlead;
  };
}
function composeRequestChain(requestChain) {
  function carleadCreationRequest(_ref4) {
    let {
      requestPayload,
      extraParams,
      response
    } = _ref4;
    //@ts-expect-error
    return requestChain.reduce((initialPromise, request) => {
      return initialPromise.then(response => {
        return request({
          requestPayload,
          extraParams,
          response
        });
      });
    }, Promise.resolve(response));
  }
  //@ts-expect-error
  return carleadCreationRequest;
}
function parseLicensePlateSearchToValues(response) {
  var _response$manufacture, _response$mainType, _response$builtYear, _response$bodyType, _response$mainTypeDet, _response$datEcode;
  return {
    manufacturer: (_response$manufacture = response === null || response === void 0 ? void 0 : response.manufacturerCode) !== null && _response$manufacture !== void 0 ? _response$manufacture : undefined,
    mainType: (_response$mainType = response === null || response === void 0 ? void 0 : response.mainType) !== null && _response$mainType !== void 0 ? _response$mainType : undefined,
    builtDate: (_response$builtYear = response === null || response === void 0 ? void 0 : response.builtYear) !== null && _response$builtYear !== void 0 ? _response$builtYear : undefined,
    bodyType: (_response$bodyType = response === null || response === void 0 ? void 0 : response.bodyType) !== null && _response$bodyType !== void 0 ? _response$bodyType : undefined,
    mainTypeDetail: (_response$mainTypeDet = response === null || response === void 0 ? void 0 : response.mainTypeDetail) !== null && _response$mainTypeDet !== void 0 ? _response$mainTypeDet : undefined,
    mainTypeSub: (_response$datEcode = response === null || response === void 0 ? void 0 : response.datEcode) !== null && _response$datEcode !== void 0 ? _response$datEcode : undefined
  };
}

//TODO: Refactor location things into a module
function redirectOnLicensePlateError(path) {
  const url = new URL(window.location.href);
  url.pathname = path;
  url.searchParams.set('error', LICENSE_PLATE_NOT_RECOGNIZED);
  url.searchParams.set(LICENSE_PLATE_FALLBACK, LICENSE_PLATE);
  window.location.href = url.toString();
}