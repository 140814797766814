import { useLingui } from '@lingui/react';
import { useAuthState as _useAuthState } from '@wkda/auth-manager';
import { useFeatures } from '@wkda/feature-toggle';
import { useHasMounted } from './useHasMounted';
export default function useAuthState() {
  const {
    i18n
  } = useLingui();
  const hasMounted = useHasMounted();
  const {
    isAuthValid,
    authState
  } = _useAuthState();
  const {
    hasCustomerAccount
  } = useFeatures();
  const loginItem = hasMounted ? {
    type: 'login',
    title: i18n._('selfInspection.header.loginTitle'),
    link: '/login/'
  } : undefined;
  const loggedInItem = hasMounted ? {
    type: 'account',
    title: i18n._('selfInspection.header.accountTitle'),
    link: '/my-account/'
  } : undefined;
  return {
    authState,
    isAuthValid,
    loginItem: hasCustomerAccount ? loginItem : undefined,
    loggedInItem: hasCustomerAccount ? loggedInItem : undefined,
    hasCustomerAccount: hasCustomerAccount
  };
}