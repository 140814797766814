import { valueIsEmpty } from '@wkda/funnel-utils';
import pick from 'lodash-es/pick';
import { flowToArray, getIterableFlow } from '../../FormsStateMachine/helpers/parseFlowToMachine';
export function useFormQuestionnaireProps(flow) {
  const questionaireItems = Object.fromEntries(Object.entries(flow).filter(_ref => {
    let [_, value] = _ref;
    return isQuestionnaireField(value);
  }));
  const questionnaireFields = flowToArray(questionaireItems).map(value => {
    return value.name;
  });
  return {
    createSteps: [function questionnaireSteps(_ref2) {
      var _step1$concat;
      let [step1] = _ref2;
      return [(_step1$concat = step1 === null || step1 === void 0 ? void 0 : step1.concat(questionnaireFields)) !== null && _step1$concat !== void 0 ? _step1$concat : []];
    }],
    questionnaireMappers: [
    //@ts-expect-error we are picking only the questionnaire field values
    function mapQuestionnaires(_ref3) {
      let {
        values
      } = _ref3;
      return pick(values, questionnaireFields);
    }],
    createCustomValidators: [function questionnaireValidators(_ref4) {
      let {
        validators
      } = _ref4;
      let questionnaireValidators = {};
      for (const [current, previous] of getIterableFlow(questionaireItems)) {
        const currentFieldName = current.name;
        // if there is no previous we could assume that this is the first item
        if (!previous) {
          questionnaireValidators[currentFieldName] = function validator(_ref5) {
            let {
              values,
              errorMessages
            } = _ref5;
            if (valueIsEmpty(values[currentFieldName])) {
              return errorMessages.selectAnswer;
            }
            return true;
          };
          continue;
        }
        const {
          name: previousFieldName,
          inputFieldType: previousFieldType
        } = previous;
        questionnaireValidators[currentFieldName] = function validator(_ref6) {
          var _previous$guards$curr;
          let {
            values,
            errorMessages
          } = _ref6;
          // of previous is of select type (can have branches)
          // check that the selected answer for that field does
          // not contain the current field name in it's guards
          // that way we know that we don't need to validate the current field
          // because it won't be visible
          if (previousFieldType === 'select' && !((_previous$guards$curr = previous.guards[currentFieldName]) !== null && _previous$guards$curr !== void 0 && _previous$guards$curr.includes(values[previousFieldName]))) {
            return true;
          }
          if (valueIsEmpty(values[currentFieldName])) {
            return errorMessages.selectAnswer;
          }
          return true;
        };
      }
      return {
        ...validators,
        ...questionnaireValidators
      };
    }]
  };
}
function isQuestionnaireField(field) {
  return field.nodeType === 'questionnaireField';
}