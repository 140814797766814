import { useEffect, useState } from 'react';
import { useCookieBannerQuery } from './useDataHooks';
import { useGTM } from './useGTM';
import { useHasMounted } from './useHasMounted';

/**
 * @param {Object} params
 * @param {(script: string) => void} params.onStart
 */
export function useGTMInit(_ref) {
  let {
    onStart
  } = _ref;
  const [isGTMStarted, setISGTMStarted] = useState(false);
  const gtmService = useGTM({
    onStart
  });
  const {
    data,
    status
  } = useCookieBannerQuery();
  const isMounted = useHasMounted();
  useEffect(() => {
    if (isGTMStarted) return;
    if (status === 'success') {
      if (!data) {
        gtmService.startImmediately();
        setISGTMStarted(true);
      } else if (isMounted) {
        gtmService.init();
        setISGTMStarted(true);
      }
    }
  }, [status, data, isMounted, isGTMStarted, gtmService]);
  return {
    gtmService: gtmService
  };
}