import { carleadQuestionnairePartialFlow } from './partialQuestionnaireFlows';
export const defaultCarleadSplitFlow = {
  initial: {
    name: 'manufacturer',
    next: ['mainType'],
    nodeType: 'dataField',
    inputFieldType: 'select'
  },
  mainType: {
    name: 'mainType',
    next: ['builtDate'],
    nodeType: 'dataField',
    inputFieldType: 'select'
  },
  builtDate: {
    name: 'builtDate',
    next: ['bodyType'],
    nodeType: 'dataField',
    inputFieldType: 'select'
  },
  bodyType: {
    name: 'bodyType',
    next: ['fuelType'],
    nodeType: 'dataField',
    inputFieldType: 'select'
  },
  fuelType: {
    name: 'fuelType',
    next: ['mainTypeDetail'],
    nodeType: 'dataField',
    inputFieldType: 'select'
  },
  mainTypeDetail: {
    name: 'mainTypeDetail',
    next: ['horsePower'],
    nodeType: 'dataField',
    inputFieldType: 'select'
  },
  horsePower: {
    name: 'horsePower',
    next: ['gearType'],
    nodeType: 'dataField',
    inputFieldType: 'select'
  },
  gearType: {
    name: 'gearType',
    next: ['doorCount'],
    nodeType: 'dataField',
    inputFieldType: 'select'
  },
  doorCount: {
    name: 'doorCount',
    next: ['mainTypeSub'],
    nodeType: 'dataField',
    inputFieldType: 'select'
  },
  mainTypeSub: {
    name: 'mainTypeSub',
    next: ['mileage'],
    nodeType: 'dataField',
    inputFieldType: 'select'
  },
  mileage: {
    name: 'mileage',
    next: ['SELLPOINT'],
    nodeType: 'normalField',
    inputFieldType: 'text'
  },
  email: {
    name: 'email',
    next: ['finish'],
    nodeType: 'normalField',
    inputFieldType: 'text'
  },
  finish: {
    name: 'finish',
    nodeType: 'normalField',
    inputFieldType: 'select',
    next: []
  },
  ...carleadQuestionnairePartialFlow
};