import { useQuery } from '@tanstack/react-query';
import { useModulesStore } from '../useModules';
import { useGetConsumerCarlead } from './consumerCarlead';
export function useDoubleOptInConfig(carleadHash) {
  const {
    crmModule
  } = useModulesStore();
  const {
    data: consumerCarlead
  } = useGetConsumerCarlead(carleadHash);
  const isDOI = consumerCarlead && !consumerCarlead.canSeePrice;
  return useQuery({
    queryKey: ['doubleOptInConfig', carleadHash],
    queryFn: () => crmModule.getDoubleOptInConfig(carleadHash),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!carleadHash && isDOI
  });
}