import { useSelector } from '@xstate/react';
import cx from 'classnames';
import pick from 'lodash-es/pick';
import { useCallback, useMemo } from 'react';
import { useFormMachine } from '../../Form/CarleadCreationForm';
import { BLUR_INPUT, FILL_INPUT, FOCUS_INPUT, SELECT } from '../../FormsStateMachine/machineModels/eventGenerators';
import { useContextValuesSelector, useDataFieldsSelector, useHistoryStackSelector, useIsCurrentFieldSelector, useIsEditingPreviousFieldSelector } from '../../FormsStateMachine/stateSelectors';
export function useStateMachineForFields(_ref) {
  let {
    name,
    currentStepPredicate = (i, n) => i.includes(n)
  } = _ref;
  const machineFormService = useFormMachine();
  const stack = useHistoryStackSelector();
  const isCurrentStep = useIsCurrentFieldSelector(name, currentStepPredicate);
  const {
    allPreviousHaveValues,
    hasValue,
    previousHasValue
  } = usePreviousValuesForField(name);
  const isEditingPreviousField = useIsEditingPreviousFieldSelector(name);
  const isPastField = stack.includes(name);
  const isFieldEnabled = isCurrentStep || isPastField || isEditingPreviousField || allPreviousHaveValues;
  const stateFieldClassNames = cx({
    'carlead-creation-field-container--is-selected': isPastField,
    'carlead-creation-field-container--is-current': isCurrentStep,
    'carlead-creation-field-container--is-disabled': !isFieldEnabled,
    'carlead-creation-field-container--has-value': hasValue,
    'carlead-creation-field-container--previous-has-value': previousHasValue,
    'carlead-creation-field-container--all-previous-have-values': allPreviousHaveValues
  });
  const selectEvent = useCallback(function selectEvent(value, selectionType) {
    machineFormService.send(SELECT(name, value, selectionType));
  }, [machineFormService, name]);
  const selectUserEvent = useCallback(function selectFromUser(value) {
    selectEvent(value, 'user');
  }, [selectEvent]);
  const sendFocus = useCallback(function sendFocus() {
    machineFormService.send(FOCUS_INPUT(name));
  }, [machineFormService, name]);
  const fillInput = useCallback(function selectEvent(value) {
    machineFormService.send(FILL_INPUT(name, value));
  }, [machineFormService, name]);
  const sendBlur = useCallback(function selectEvent() {
    machineFormService.send(BLUR_INPUT(name));
  }, [machineFormService, name]);
  return {
    isPastField,
    isInCurrentField: isCurrentStep,
    isFieldEnabled,
    stateFieldClassNames,
    sendSelect: selectEvent,
    sendUserSelect: selectUserEvent,
    sendFocus,
    fillInput,
    sendBlur
  };
}
export function useStateMachineForDataFields(_ref2) {
  let {
    name,
    currentStepPredicate = (i, n) => i.includes(n + ".loaded")
  } = _ref2;
  const machine = useStateMachineForFields({
    name,
    currentStepPredicate
  });
  const dataValues = useContextValuesSelector();
  const dataFields = useDataFieldsSelector();
  const dataFieldsUntilStep = useMemo(() => {
    return dataFields.slice(0, dataFields.indexOf(name));
  }, [dataFields, name]);
  const params = pick(dataValues, dataFieldsUntilStep);
  return {
    ...machine,
    dataFields,
    dataFieldsUntilStep,
    dataValues,
    fieldFetchParams: params
  };
}
export function usePreviousValuesForField(name) {
  const values = useContextValuesSelector();
  const machineFormService = useFormMachine();
  const previousFieldsMap = useSelector(machineFormService, state => {
    var _state$context$previo;
    const previousSelectedFieldsMap = (_state$context$previo = state.context.previousSelectedFieldsMap) !== null && _state$context$previo !== void 0 ? _state$context$previo : {};
    return previousSelectedFieldsMap;
  });
  let names = getArrayOfPreviousSteps(previousFieldsMap, name);
  const allPreviousHaveValues = names.length > 0 ? names.every(item => {
    // eslint-disable-next-line eqeqeq
    return values[item] != undefined && values[item] !== '';
  }) : false;
  const hasValue = values[name] != null && values[name] !== '';
  const previousHasValue = !!values[previousFieldsMap[name]];
  const value = useMemo(() => {
    return {
      allPreviousHaveValues,
      hasValue,
      previousHasValue
    };
  }, [allPreviousHaveValues, hasValue, previousHasValue]);
  return value;
}
export function getGraphTails(graph) {
  const parents = new Set();
  const children = new Set();
  const tailNodes = [];
  for (const child in graph) {
    children.add(child);
    parents.add(graph[child]);
  }
  for (const parent of parents) {
    if (!children.has(parent)) {
      tailNodes.push(parent);
    }
  }
  return tailNodes;
}
export function getArrayOfPreviousSteps(previousValues, initialName) {
  let includeInitial = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  let previousName = previousValues[initialName];
  let names = includeInitial ? [initialName] : [];
  while (previousName) {
    names.push(previousName);
    previousName = previousValues[previousName];
  }
  return names;
}