export const DSBFieldsMap = {
  manufacturer: 'manufacturer',
  model: 'model',
  builtYear: 'builtYear',
  bodyType: 'bodyType',
  modelDetails: 'modelDetails',
  modelDetailsSub: 'modelDetailsSub',
  mileage: 'mileage',
  exactMileage: 'exactMileage',
  email: 'email',
  zipCode: 'zipCode',
  licensePlate: 'licensePlate',
  fuelType: 'fuelType',
  horsePower: 'horsePower',
  gearType: 'gearType',
  doorCount: 'doorCount'
};