function checkSuspiciousMileageByYear(year, mileage, lowerThreshold, upperThreshold) {
  if (isNaN(year) || year <= 0) return false;
  const howOldIsCar = new Date().getFullYear() - year;
  const calculatedUpperThreshold = howOldIsCar * upperThreshold;
  const calculatedLowerThreshold = howOldIsCar * lowerThreshold;
  return mileage < calculatedLowerThreshold || mileage > calculatedUpperThreshold;
}
export function checkIfMileageSuspicious(_ref) {
  let {
    year,
    mileage,
    lowerThreshold,
    upperThreshold
  } = _ref;
  if (!mileage || !lowerThreshold || !upperThreshold || Number.isNaN(mileage)) {
    return false;
  }
  return typeof year === 'number' ? checkSuspiciousMileageByYear(year, mileage, lowerThreshold, upperThreshold) : mileage < lowerThreshold || mileage > upperThreshold;
}