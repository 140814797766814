import { Mailcheck } from '@wkda/funnel-utils';
import { emailSuggestionConfiguration } from './configuration';

/**
 * @typedef {import('./types').IMailCheckOptions} IMailCheckOptions
 */

/**
 *
 * @param {IMailCheckOptions} props
 * @param {import('./types').IEmailSuggestionConfiguration} configuration
 */
export function mailCheck(_ref) {
  var _listTopLevelDomainsB;
  let {
    email,
    suggested,
    empty,
    locale
  } = _ref;
  let configuration = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : emailSuggestionConfiguration;
  const {
    domainThreshold,
    secondLevelThreshold,
    topLevelThreshold,
    listDomains: domains,
    listTopLevelDomains,
    listSecondLevelDomains: secondLevelDomains,
    listTopLevelDomainsByLocales,
    listPrioritizedTopLevelDomains,
    fixedTopLevelDomains
  } = configuration;
  Mailcheck.domainThreshold = domainThreshold !== null && domainThreshold !== void 0 ? domainThreshold : Mailcheck.domainThreshold;
  Mailcheck.topLevelThreshold = topLevelThreshold !== null && topLevelThreshold !== void 0 ? topLevelThreshold : Mailcheck.topLevelThreshold;
  Mailcheck.secondLevelThreshold = secondLevelThreshold !== null && secondLevelThreshold !== void 0 ? secondLevelThreshold : Mailcheck.secondLevelThreshold;
  const topLevelDomains = [...new Set([...((_listTopLevelDomainsB = listTopLevelDomainsByLocales[locale]) !== null && _listTopLevelDomainsB !== void 0 ? _listTopLevelDomainsB : []), ...listPrioritizedTopLevelDomains, ...listTopLevelDomains])];
  Mailcheck.run({
    email,
    fixedTopLevelDomains,
    domains,
    secondLevelDomains,
    topLevelDomains,
    suggested,
    empty
  });
}