import debounce from 'lodash-es/debounce';
import { isIOS, getIsElementIntersecting, getIsInIframe } from '@wkda/funnel-utils';
const focus = element => {
  function _focus() {
    return isIOS() ? element.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    }) : element.focus();
  }
  if (typeof window !== 'undefined') {
    if (getIsInIframe()) {
      getIsElementIntersecting(element).then(res => {
        if (res) {
          return _focus();
        }
      });
    } else {
      _focus();
    }
  }
};
const debouncedFocus = debounce(focus, 20);
export { debouncedFocus as focus };