/**
 * Takes locale string to return config for that trustpilot.
 *
 * @param {string} locale the respective country's locale
 *
 * By default it returns config for "de-DE"
 */

export default function trustpilotConfig(locale) {
  switch (locale) {
    case 'de':
      return {
        className: 'trustpilot-widget',
        locale: 'de-DE',
        templateId: '5406e65db0d04a09e042d5fc',
        businessUnitId: '557b5f2e0000ff00058029ab',
        height: '28px',
        width: '100%',
        theme: 'light',
        href: 'https://de.trustpilot.com/review/wirkaufendeinauto.de',
        target: '_blank',
        rel: 'noopener',
        label: 'Trustpilot',
        stars: '4,5',
        clickable: true
      };
    case 'it':
      return {
        className: 'trustpilot-widget',
        locale: 'it-IT',
        templateId: '5406e65db0d04a09e042d5fc',
        businessUnitId: '54e2eb320000ff00057d7803',
        height: '28px',
        width: '100%',
        theme: 'light',
        href: 'https://it.trustpilot.com/review/www.noicompriamoauto.it',
        target: '_blank',
        rel: 'noopener',
        label: 'Trustpilot',
        stars: '4,5',
        clickable: true
      };
    case 'es':
      return {
        className: 'trustpilot-widget',
        locale: 'es-ES',
        templateId: '5406e65db0d04a09e042d5fc',
        businessUnitId: '55fbe9050000ff0005837144',
        height: '28px',
        width: '100%',
        theme: 'light',
        href: 'https://es.trustpilot.com/review/www.compramostucoche.es',
        target: '_blank',
        rel: 'noopener',
        label: 'Trustpilot',
        stars: '4,5',
        clickable: true
      };
    case 'fr':
      return {
        className: 'trustpilot-widget',
        locale: 'fr-FR',
        templateId: '5406e65db0d04a09e042d5fc',
        businessUnitId: '5a0233290000ff0005afd532',
        height: '28px',
        width: '100%',
        theme: 'light',
        href: 'https://fr.trustpilot.com/review/vendezvotrevoiture.fr',
        target: '_blank',
        rel: 'noopener',
        label: 'Trustpilot',
        stars: '4,5',
        clickable: true
      };
    case 'nl-BE':
      return {
        className: 'trustpilot-widget',
        locale: 'nl-BE',
        templateId: '5406e65db0d04a09e042d5fc',
        businessUnitId: '560a46720000ff000583d31b',
        height: '28px',
        width: '100%',
        theme: 'light',
        href: 'https://nl-be.trustpilot.com/review/wijkopenautos.be',
        target: '_blank',
        rel: 'noopener',
        label: 'Trustpilot',
        stars: '4,5',
        clickable: true
      };
    case 'fr-BE':
      return {
        className: 'trustpilot-widget',
        locale: 'fr-BE',
        templateId: '5406e65db0d04a09e042d5fc',
        businessUnitId: '598ccb6d0000ff0005a89789',
        height: '28px',
        width: '100%',
        theme: 'light',
        href: 'https://fr-be.trustpilot.com/review/www.vendezvotrevoiture.be',
        target: '_blank',
        rel: 'noopener',
        label: 'Trustpilot',
        stars: '4,5',
        clickable: true
      };
    case 'de-AT':
      return {
        className: 'trustpilot-widget',
        locale: 'de-AT',
        templateId: '5406e65db0d04a09e042d5fc',
        businessUnitId: '5b3265362a5f120001de47de',
        height: '28px',
        width: '100%',
        theme: 'light',
        href: 'https://at.trustpilot.com/review/wirkaufendeinauto.at',
        target: '_blank',
        rel: 'noopener',
        label: 'Trustpilot',
        stars: '4,5',
        clickable: true
      };
    case 'sv-SE':
      return {
        className: 'trustpilot-widget',
        locale: 'sv-SE',
        templateId: '5419b732fbfb950b10de65e5',
        businessUnitId: '560a49620000ff000583d336',
        height: '24px',
        width: '100%',
        theme: 'light',
        href: 'https://se.trustpilot.com/review/vikoperdinbil.se',
        target: '_blank',
        rel: 'noopener',
        label: 'Trustpilot',
        stars: '4,5',
        clickable: true
      };
    case 'pt':
      return {
        className: 'trustpilot-widget',
        locale: 'pt-PT',
        templateId: '5406e65db0d04a09e042d5fc',
        businessUnitId: '602438abedd7e00001af84dc',
        height: '28px',
        width: '100%',
        theme: 'light',
        href: 'https://pt.trustpilot.com/review/compramososeucarro.pt',
        target: '_blank',
        rel: 'noopener',
        label: 'Trustpilot',
        stars: '4,5',
        clickable: true
      };
    case 'nl':
      return {
        className: 'trustpilot-widget',
        locale: 'nl',
        templateId: '5419b6ffb0d04a076446a9af',
        businessUnitId: '56a53f160000ff0005880825',
        height: '20px',
        width: '100%',
        theme: 'light',
        href: 'https://nl.trustpilot.com/review/wijkopenautos.n',
        target: '_blank',
        rel: 'noopener',
        label: 'Trustpilot',
        stars: '4,5',
        clickable: true
      };
    default:
      return {
        className: 'trustpilot-widget',
        locale: 'de-DE',
        templateId: '5406e65db0d04a09e042d5fc',
        businessUnitId: '557b5f2e0000ff00058029ab',
        height: '28px',
        width: '100%',
        theme: 'light',
        href: 'https://de.trustpilot.com/review/wirkaufendeinauto.de',
        target: '_blank',
        rel: 'noopener',
        label: 'Trustpilot',
        stars: '4,5',
        clickable: true
      };
  }
}