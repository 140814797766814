import { getAuthTokenDetails, getCustomerAuthentication, getDsbLocalStorageKey, localStore } from '@wkda/funnel-utils';
import { parse } from 'query-string';
import Cookies from 'universal-cookie';
import { formNames } from './formNames';
export function createMarketingProperties(config, marketingProperties) {
  let _defaultMappers = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : sharedMarketingProps;
  return function $createMarketingProperties(mappers, values) {
    return _defaultMappers.concat(mappers).reduce(function mapperReducer(payload, mapperFn) {
      return Object.assign({}, payload, mapperFn({
        config,
        values,
        marketingProperties,
        properties: payload
      }));
    }, {});
  };
}
export const hideOnlinePriceMPMapper = function hideOnlinePriceMPMapper(_ref) {
  var _config$featuresFlags;
  let {
    config,
    marketingProperties
  } = _ref;
  if ((config === null || config === void 0 || (_config$featuresFlags = config.featuresFlags) === null || _config$featuresFlags === void 0 ? void 0 : _config$featuresFlags.selfEvaHideOnlinePriceBucket) === true) {
    return {
      [marketingProperties.hideOnlinePrice.propertyId]: 1
    };
  }
  return {};
};
export const selfEvaluationRedirectMPMapper = function selfEvaluationRedirectMPMapper(_ref2) {
  var _config$featuresFlags2;
  let {
    config,
    marketingProperties
  } = _ref2;
  if ((config === null || config === void 0 || (_config$featuresFlags2 = config.featuresFlags) === null || _config$featuresFlags2 === void 0 ? void 0 : _config$featuresFlags2.redirectToSelfEvaluation) === true) {
    return {
      [marketingProperties.slimFunnels.propertyId]: 1
    };
  }
  return {};
};
export const selfEvaluationAffiliateMPMapper = function selfEvaluationAffiliateMPMapper(_ref3) {
  var _config$featuresFlags3;
  let {
    config,
    marketingProperties
  } = _ref3;
  const affiliateScenario = config === null || config === void 0 || (_config$featuresFlags3 = config.featuresFlags) === null || _config$featuresFlags3 === void 0 ? void 0 : _config$featuresFlags3.affiliateScenario;
  if (['affiliate', 'whitelabel'].includes(affiliateScenario)) {
    return {
      [marketingProperties.affiliateScenario.propertyId]: affiliateScenario
    };
  }
  return {};
};
export const honeypotMPMapper = function honeypotMPMapper(_ref4) {
  var _config$featuresFlags4;
  let {
    config,
    marketingProperties,
    values
  } = _ref4;
  if ((config === null || config === void 0 || (_config$featuresFlags4 = config.featuresFlags) === null || _config$featuresFlags4 === void 0 ? void 0 : _config$featuresFlags4.honeyPotEmail) === true && values[formNames.honeyPotEmail]) {
    return {
      [marketingProperties.honeyPot.propertyId]: 1
    };
  }
  return {};
};
export const postCodeMapper = function postCodeMapper(_ref5) {
  let {
    marketingProperties,
    values
  } = _ref5;
  if (values.location) {
    return {
      [marketingProperties.postalCode.propertyId]: JSON.stringify(values.location)
    };
  }
  return {};
};
export const gdprPreferencesMPMapper = function gdprPreferencesMPMapper(_ref6) {
  let {
    marketingProperties
  } = _ref6;
  const cookies = new Cookies();
  const gdprCookie = cookies.get('gdpr_preferences');
  if (gdprCookie) {
    return {
      [marketingProperties.GDPRPreferences.propertyId]: JSON.stringify(gdprCookie)
    };
  }
  return {};
};
export const skipOfferEmailMapper = function skipOfferEmail(_ref7) {
  var _config$featuresFlags5;
  let {
    marketingProperties,
    config
  } = _ref7;
  const {
    propertyId,
    value
  } = marketingProperties.skipOfferEmail;
  const {
    skipOfferEmail
  } = (_config$featuresFlags5 = config === null || config === void 0 ? void 0 : config.featuresFlags) !== null && _config$featuresFlags5 !== void 0 ? _config$featuresFlags5 : {};
  // eslint-disable-next-line eqeqeq
  if (skipOfferEmail === false || skipOfferEmail == undefined) {
    return {};
  }
  return {
    [propertyId]: value
  };
};
export const emailSSOSourceMapper = function emailSSOSource(_ref8) {
  let {
    marketingProperties,
    values
  } = _ref8;
  const {
    emailSSOSource
  } = values;
  const {
    propertyId
  } = marketingProperties.emailSSOSource;
  if (emailSSOSource) {
    return {
      [propertyId]: emailSSOSource
    };
  }
  return {};
};
export const partnerFunnelMPMapper = function (_ref9) {
  let {
    marketingProperties,
    config
  } = _ref9;
  let props = {};
  if (config.isPartnerFunnel && typeof window !== 'undefined') {
    const {
      partnerAPIKey,
      partnerDomain
    } = marketingProperties;
    const currentURL = new URL(window.location.href);
    const waKey = currentURL.searchParams.get('wa_key');
    const url = currentURL.searchParams.get('partner_url');
    if (waKey) {
      props[partnerAPIKey.propertyId] = waKey;
    }
    if (url) {
      props[partnerDomain.propertyId] = url;
    }
  }
  return props;
};
export const estateAgentMPMapper = function (_ref10) {
  var _config$featuresFlags6;
  let {
    marketingProperties,
    config
  } = _ref10;
  const {
    propertyId,
    value
  } = marketingProperties.estateAgentFunnel;
  const {
    estateAgentFunnel
  } = (_config$featuresFlags6 = config === null || config === void 0 ? void 0 : config.featuresFlags) !== null && _config$featuresFlags6 !== void 0 ? _config$featuresFlags6 : {};
  if (!estateAgentFunnel) {
    return {};
  }
  return {
    [propertyId]: value
  };
};
export const licensePlateMarketingPropertyMapper = function licensePlateMarketingPropertyMapper(_ref11) {
  let {
    values,
    marketingProperties
  } = _ref11;
  const {
    propertyId
  } = marketingProperties.licensePlate;
  if (!values.licensePlate) {
    return {};
  }
  return {
    [propertyId]: cleanLicensePlate(values.licensePlate)
  };
};
const LICENSE_PLATE_NOT_RECOGNIZED = 'licensePlateNotRecognized';
const LICENSE_PLATE = 'licensePlate';
const LICENSE_PLATE_FALLBACK = 'fallbackType';
export const invalidLicensePlateMarketingPropertyMapper = function licensePlateMarketingPropertyMapper(_ref12) {
  var _localStore$getJSON;
  let {
    marketingProperties,
    config
  } = _ref12;
  const {
    propertyId
  } = marketingProperties.licensePlateNotRecognized;
  const query = parse(typeof window !== 'undefined' ? window.location.search : '');
  const notRecognised = (query === null || query === void 0 ? void 0 : query.error) === LICENSE_PLATE_NOT_RECOGNIZED;
  const licensePlate = (_localStore$getJSON = localStore.getJSON(getDsbLocalStorageKey(config.fullLocale))) === null || _localStore$getJSON === void 0 || (_localStore$getJSON = _localStore$getJSON.licensePlate) === null || _localStore$getJSON === void 0 ? void 0 : _localStore$getJSON.value;
  if (!licensePlate || !notRecognised || typeof licensePlate !== 'string') {
    return {};
  }
  return {
    [propertyId]: cleanLicensePlate(licensePlate)
  };
};
export const licensePlateFallbackMarketingPropertyMapper = function licensePlateFallbackMarketingPropertyMapper(_ref13) {
  let {
    marketingProperties
  } = _ref13;
  const {
    propertyId
  } = marketingProperties.licensePlateFallback;
  const query = parse(typeof window !== 'undefined' ? window.location.search : '');
  const licensePlateFallback = query === null || query === void 0 ? void 0 : query[LICENSE_PLATE_FALLBACK];
  const fallback = typeof licensePlateFallback === 'string' ? cleanQueryParam(licensePlateFallback) : '';
  if (fallback !== LICENSE_PLATE) {
    return {};
  }
  return {
    [propertyId]: 1
  };
};
export const unspecifiedMarketingExperimentMapper = function unspecifiedMarketingExperimentMapper(_ref14) {
  var _config$featuresFlags7;
  let {
    marketingProperties,
    config
  } = _ref14;
  const unspecifiedExperiment = config === null || config === void 0 || (_config$featuresFlags7 = config.featuresFlags) === null || _config$featuresFlags7 === void 0 ? void 0 : _config$featuresFlags7.unspecifiedExperiment;
  const {
    propertyId
  } = marketingProperties.unspecifiedExperiment;
  if (unspecifiedExperiment === true) {
    return {
      [propertyId]: 1
    };
  }
  return {};
};
export const dropOffEmailDelayMapper = function dropOffEmailDelayMapper(_ref15) {
  var _config$featuresFlags8;
  let {
    marketingProperties,
    config
  } = _ref15;
  const dropOffEmailDelayInMinutes = config === null || config === void 0 || (_config$featuresFlags8 = config.featuresFlags) === null || _config$featuresFlags8 === void 0 ? void 0 : _config$featuresFlags8.dropOffEmailDelayInMinutes;
  const {
    propertyId
  } = marketingProperties.dropOffEmailDelay;
  if (typeof dropOffEmailDelayInMinutes === 'number' && Number.isInteger(dropOffEmailDelayInMinutes)) {
    return {
      [propertyId]: dropOffEmailDelayInMinutes
    };
  }
  return {};
};
export const leadCIDChannelMapper = function leadCIDChannelMapper(_ref16) {
  var _config$featuresFlags9;
  let {
    marketingProperties,
    config,
    values
  } = _ref16;
  const shouldSend = config === null || config === void 0 || (_config$featuresFlags9 = config.featuresFlags) === null || _config$featuresFlags9 === void 0 ? void 0 : _config$featuresFlags9.sendLeadCIDChannel;
  if (shouldSend === false) {
    return {};
  }
  const CID_COOKIE_KEY = 'WKDA';
  const {
    propertyId
  } = marketingProperties.leadCIDChannel;
  const {
    latestCIDchannel
  } = values;
  const cookies = new Cookies();
  const wkdaCookie = cookies.get(CID_COOKIE_KEY);
  const parsedLatestCIDchannel = getLatestCIDchannel(wkdaCookie);
  const channelValue = parsedLatestCIDchannel !== null && parsedLatestCIDchannel !== void 0 ? parsedLatestCIDchannel : latestCIDchannel;
  return channelValue !== undefined ? {
    [propertyId]: channelValue
  } : {};
};
export const noEmailMarketingPropertyMapper = function noEmailMarketingPropertyMapper(_ref17) {
  let {
    marketingProperties
  } = _ref17;
  const {
    propertyId
  } = marketingProperties.noEmailField;
  return {
    [propertyId]: 1
  };
};
export const socialLoginMarketingPropertyMapper = function socialLoginMarketingPropertyMapper(_ref18) {
  var _auth$user;
  let {
    marketingProperties,
    values
  } = _ref18;
  const properties = {};
  const auth = getCustomerAuthentication();
  const tokenDetails = getAuthTokenDetails();

  // user logged-in via social login & email matches
  if (tokenDetails.authType === 'social' && (auth === null || auth === void 0 || (_auth$user = auth.user) === null || _auth$user === void 0 ? void 0 : _auth$user.email) === values.email) {
    properties[marketingProperties.socialLogin.propertyId] = 1;
    // if one-tap login was used
    if (auth.one_tap) {
      properties[marketingProperties.oneTapLogin.propertyId] = 1;
    }
  }
  return properties;
};
export const sharedMarketingProps = [hideOnlinePriceMPMapper, selfEvaluationRedirectMPMapper, honeypotMPMapper, gdprPreferencesMPMapper, skipOfferEmailMapper, emailSSOSourceMapper, partnerFunnelMPMapper, estateAgentMPMapper, invalidLicensePlateMarketingPropertyMapper, licensePlateFallbackMarketingPropertyMapper, selfEvaluationAffiliateMPMapper, unspecifiedMarketingExperimentMapper, dropOffEmailDelayMapper, leadCIDChannelMapper, socialLoginMarketingPropertyMapper];
export function createMarketingPropertiesDictionary() {
  return {
    honeyPot: {
      propertyId: 3470,
      value: 1
    },
    slimFunnels: {
      propertyId: 8762,
      value: 1
    },
    hideOnlinePrice: {
      propertyId: 8764,
      value: 1
    },
    GDPRPreferences: {
      propertyId: 8790,
      value: ''
    },
    postalCode: {
      propertyId: 5902,
      value: ''
    },
    skipOfferEmail: {
      propertyId: 8755,
      value: 1
    },
    partnerAPIKey: {
      propertyId: 9100,
      value: ''
    },
    partnerDomain: {
      propertyId: 9101,
      value: ''
    },
    estateAgentFunnel: {
      propertyId: 6351,
      value: 'estate_agent_funnel'
    },
    emailSSOSource: {
      propertyId: 8745,
      value: ''
    },
    licensePlate: {
      propertyId: 8600,
      value: ''
    },
    licensePlateNotRecognized: {
      propertyId: 8601,
      value: ''
    },
    licensePlateFallback: {
      propertyId: 6350,
      value: 1
    },
    affiliateScenario: {
      propertyId: 9102,
      value: 'none'
    },
    unspecifiedExperiment: {
      propertyId: 8756,
      value: 1
    },
    dropOffEmailDelay: {
      propertyId: 9204,
      value: 0
    },
    leadCIDChannel: {
      propertyId: 8700,
      value: ''
    },
    noEmailField: {
      propertyId: 8743,
      value: 1
    },
    obfuscatedPrice: {
      propertyId: 8781,
      value: ''
    },
    oneTapLogin: {
      propertyId: 6850,
      value: 1
    },
    socialLogin: {
      propertyId: 6851,
      value: 1
    },
    diversionAccount: {
      propertyId: 6852,
      value: 1
    }
  };
}
function cleanLicensePlate() {
  let licensePlate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return licensePlate.replace(/\W|_/g, '');
}
function cleanQueryParam(query) {
  return query.replace(/[^\w\s]/gi, '');
}
function getLatestCIDchannel(cookie) {
  var _decodeURIComponent$s;
  if (!cookie || !decodeURIComponent) {
    return undefined;
  }
  return (_decodeURIComponent$s = decodeURIComponent(cookie).split('||')) === null || _decodeURIComponent$s === void 0 || (_decodeURIComponent$s = _decodeURIComponent$s.at(-1)) === null || _decodeURIComponent$s === void 0 || (_decodeURIComponent$s = _decodeURIComponent$s.split('&')) === null || _decodeURIComponent$s === void 0 || (_decodeURIComponent$s = _decodeURIComponent$s.at(0)) === null || _decodeURIComponent$s === void 0 || (_decodeURIComponent$s = _decodeURIComponent$s.split('_')) === null || _decodeURIComponent$s === void 0 ? void 0 : _decodeURIComponent$s.at(1);
}