import { useEffect, useRef } from 'react';
export function useTrackInputActivation(name, cond, onFieldEnabled) {
  const hasCalled = useRef(false);
  useEffect(() => {
    if (onFieldEnabled && cond && !hasCalled.current) {
      hasCalled.current = true;
      const time = Date.now() - window.performance.timing.navigationStart;
      onFieldEnabled(name, time);
    }
  }, [name, onFieldEnabled, cond]);
}