import { useGetConsumerCarlead, useGetWKDALeadCookie, useLastAppointment } from '@wkda/funnel-components';
import { CAR_PROPERTIES, CAR_PROPERTIES_VALUES, directBookingSelector, marketingExperimentSelector, selfInspectionSelector } from '@wkda/funnel-modules';
import consumerCarleadStatuses from '../consumerCarleadStatuses';
import { bannerNames, getBannerName } from '../getBannerName';
import { useCarleadConfiguration } from './useCarleadCreationConfiguration';
function useGetCustomerBannerName() {
  const wkdaLead = useGetWKDALeadCookie();
  const {
    data: consumerCarlead
  } = useGetConsumerCarlead(wkdaLead);
  const carleadAppointment = useLastAppointment(wkdaLead);
  if (!consumerCarlead) {
    return null;
  }
  return getBannerName(consumerCarlead, carleadAppointment === null || carleadAppointment === void 0 ? void 0 : carleadAppointment.status);
}
function getCarPropertyValueById(carProperties, id) {
  var _carProperties$find;
  return (_carProperties$find = carProperties.find(_ref => {
    let {
      propertyId
    } = _ref;
    return propertyId === id;
  })) === null || _carProperties$find === void 0 ? void 0 : _carProperties$find.value;
}
function useGetCustomerBannerProps(bannerName) {
  const {
    ONSITE_EXPIRED,
    L2A,
    ONSITE_VALID,
    NO_SHOW,
    APPOINTMENT_REMINDER,
    CANCELLED_APPOINTMENT
  } = bannerNames;
  const wkdaLead = useGetWKDALeadCookie();
  const {
    data: consumerCarlead
  } = useGetConsumerCarlead(wkdaLead);
  const {
    bookingUrl,
    confirmationUrl
  } = useCarleadConfiguration();
  if (!consumerCarlead || !bannerName) {
    return null;
  }
  const {
    car,
    price,
    statusId,
    carProperties,
    createdOn,
    priceExpiresInDays,
    canSeePrice,
    branch,
    appointment,
    onsiteDateTime
  } = consumerCarlead;
  const commonProps = {
    isRiskFree: getCarPropertyValueById(carProperties, CAR_PROPERTIES.CAR_PURCHASE_TRACK) === CAR_PROPERTIES_VALUES.RISK_FREE
  };
  switch (bannerName) {
    case APPOINTMENT_REMINDER:
      {
        return {
          __type: 'appointment-remainder',
          translationPrefix: onsiteDateTime ? 'appointmentReminderOnSiteRCB' : 'appointmentReminderRCB',
          hideCarDetails: directBookingSelector(consumerCarlead),
          isDoi: !canSeePrice,
          manufacturer: car.manufacturer,
          startDate: appointment === null || appointment === void 0 ? void 0 : appointment.start,
          cityBranch: branch === null || branch === void 0 ? void 0 : branch.city,
          confirmBookingUrl: onsiteDateTime ? confirmationUrl + "/" + car.hash : '',
          bookingUrl: onsiteDateTime ? bookingUrl + "/" + car.hash : "/booking/" + car.hash + "#from=banner",
          offerPrice: statusId === consumerCarleadStatuses.ONSITE_PRICE_GIVEN ? null : price,
          ...commonProps
        };
      }
    case NO_SHOW:
      {
        return {
          __type: 'no-show',
          translationPrefix: onsiteDateTime ? 'noShowOnSiteRCB' : 'noShowRCB',
          isDoi: !canSeePrice,
          manufacturer: car.manufacturer,
          hideCarDetails: directBookingSelector(consumerCarlead),
          offerPrice: statusId === consumerCarleadStatuses.ONSITE_PRICE_GIVEN ? null : price,
          cityBranch: branch === null || branch === void 0 ? void 0 : branch.city,
          startDate: appointment === null || appointment === void 0 ? void 0 : appointment.start,
          bookingUrl: onsiteDateTime ? bookingUrl + "/" + car.hash : "/booking/" + car.hash + "#from=banner",
          ...commonProps
        };
      }
    case ONSITE_VALID:
      {
        return {
          __type: 'onsite-price-valid',
          priceExpiresInDays,
          manufacturer: car.manufacturer,
          bookingUrl: "/booking/" + car.hash + "#from=banner",
          auctionSummaryUrl: "/'auction-summary/" + car.hash + "#from=banner",
          price: statusId === consumerCarleadStatuses.ONSITE_PRICE_GIVEN ? price : null,
          ...commonProps
        };
      }
    case ONSITE_EXPIRED:
      {
        return {
          __type: 'onsite-price-expired',
          bookingUrl: "/booking/" + car.hash + "#from=banner",
          manufacturer: car.manufacturer,
          price: statusId === consumerCarleadStatuses.ONSITE_PRICE_GIVEN ? price : null,
          ...commonProps
        };
      }
    case L2A:
      {
        const marketingExperiment = marketingExperimentSelector(consumerCarlead);
        const selfInspection = selfInspectionSelector(consumerCarlead);
        return {
          __type: 'L2A',
          isDoi: !canSeePrice,
          manufacturer: car.manufacturer,
          model: car.mainType,
          carleadHash: car.hash,
          selfInspection,
          inspectionUrl: "/inspection/" + wkdaLead + "/",
          emailCreatedOn: new Date(createdOn).toLocaleString('de-DE').split(', ')[0],
          offerPrice: statusId === consumerCarleadStatuses.ONSITE_PRICE_GIVEN ? null : price,
          isSelfEvaluationCompleted: getCarPropertyValueById(carProperties, CAR_PROPERTIES.ONLINE_SELF_EVALUATION) === CAR_PROPERTIES_VALUES.ONLINE_SELF_EVALUATION,
          marketingExperiment,
          ...commonProps
        };
      }
    case CANCELLED_APPOINTMENT:
      {
        return {
          __type: 'canceled-appointment',
          bookingUrl: "/booking/" + car.hash + "#from=banner"
        };
      }
    default:
      return null;
  }
}
function useGetDateInfoByTimeZone(initialDate) {
  const {
    locale,
    timezoneMapper
  } = useCarleadConfiguration();
  const date = new Date(initialDate);
  const timeZone = timezoneMapper[locale];
  // de-DE for common slash split
  const [fullDate, time] = date.toLocaleString('de-DE', {
    timeZone
  }).split(',');
  return {
    weekday: date.toLocaleString(locale, {
      weekday: 'short',
      timeZone
    }),
    date: fullDate.split('.').slice(0, 2).join('.'),
    time: time.trim().split(':').slice(0, 2).join(':')
  };
}
export { useGetCustomerBannerName, useGetCustomerBannerProps, useGetDateInfoByTimeZone };