import { defaultCarleadSplitFields } from './fieldLists';
export function useSplitFieldsProps() {
  return {
    createSteps: [function splitFieldsSteps() {
      return [[...defaultCarleadSplitFields]];
    }],
    createCustomValidators: [function leadSplitValidator(_ref) {
      let {
        validators
      } = _ref;
      return {
        ...validators,
        ...defaultCarleadSplitFields.filter(item => {
          return item !== 'email' && item !== 'mileage';
        }).reduce((acc, field) => {
          acc[field] = function fieldValidationFn(_ref2) {
            let {
              value,
              errorMessages
            } = _ref2;
            return [null, undefined, ''].includes(value) ?
            //@ts-expect-error
            errorMessages[field] : true;
          };
          return acc;
        }, {})
      };
    }]
  };
}