import { isValidEmailPattern, validateLicensePlateByLocale } from '@wkda/funnel-utils';
import { formNames } from './formNames';
export function createValidation(_ref) {
  let {
    names = formNames,
    locale,
    errors: errorMessages,
    createCustomValidators = [_ref2 => {
      let {
        validators
      } = _ref2;
      return validators;
    }],
    othersValidators = {}
  } = _ref;
  const defaultValidators = createDefaultValidators({
    formNames: names,
    locale,
    errorMessages
  });
  const customValidatorFn = composeCustomValidators(createCustomValidators);
  const customOthersValidators = {
    ...othersValidators,
    ...defaultOthersValidators
  };
  const validate = createValidate({
    errorMessages,
    locale,
    othersValidators: customOthersValidators,
    validators: customValidatorFn({
      validators: defaultValidators,
      formNames: names,
      errorMessages,
      locale
    })
  });
  return {
    validate
  };
}
function createValidate(_ref3) {
  let {
    errorMessages,
    locale,
    validators,
    othersValidators
  } = _ref3;
  return function validate(fields, formValues) {
    const fieldsWithErrors = fields.reduce(function reducer(result, field) {
      const validator = validators[field];
      // eslint-disable-next-line eqeqeq
      if (typeof validator !== 'function' || validator == undefined) {
        return result;
      }
      //TODO: find out how to better infer here
      const value = formValues[field];
      const validationResult = validator({
        values: formValues,
        value,
        locale,
        errorMessages
      });
      if (validationResult === true) {
        return result;
      }
      result[field] = typeof validationResult === 'string' ? validationResult : errorMessages.missingValue;
      return result;
    }, {});
    const otherValidations = {};
    for (const [key, validatorFn] of Object.entries(othersValidators)) {
      if (typeof validatorFn !== 'function') {
        continue;
      }
      const validationResult = validatorFn({
        values: formValues,
        errorMessages,
        locale
      });
      if (validationResult === true) {
        continue;
      }
      otherValidations[key] = validationResult;
    }

    // otherValidations should not be for actual fields,
    // but just in case, lets overwrite with fieldsWithErrors
    return {
      ...otherValidations,
      ...fieldsWithErrors
    };
  };
}
function createDefaultValidators(_ref4) {
  let {
    locale
  } = _ref4;
  return {
    manufacturer(_ref5) {
      let {
        value,
        errorMessages
      } = _ref5;
      return notEmpty(value) || errorMessages.manufacturer;
    },
    mainType(_ref6) {
      let {
        value,
        errorMessages
      } = _ref6;
      return notEmpty(value) || errorMessages.mainType;
    },
    builtDate(_ref7) {
      let {
        value,
        errorMessages
      } = _ref7;
      return notEmpty(value) || errorMessages.builtDate;
    },
    bodyType(_ref8) {
      let {
        value,
        errorMessages
      } = _ref8;
      return notEmpty(value) || errorMessages.bodyType;
    },
    mainTypeDetail(_ref9) {
      let {
        value,
        errorMessages
      } = _ref9;
      return notEmpty(value) || errorMessages.mainTypeDetail;
    },
    mainTypeSub(_ref10) {
      let {
        value,
        errorMessages
      } = _ref10;
      return notEmpty(value) || errorMessages.mainTypeSub;
    },
    mileage(_ref11) {
      let {
        value,
        errorMessages
      } = _ref11;
      const maxMileageValue = 999999999;
      const minMileageValue = 0;
      if (mileageIsOutOfBounds(value)) {
        return errorMessages.tooLongMileage;
      }
      return notEmpty(value) && Number(value) > minMileageValue && Number(value) <= maxMileageValue || errorMessages.mileage;
    },
    licensePlate(_ref12) {
      let {
        value,
        errorMessages
      } = _ref12;
      // TODO: Add support for all locales
      const hasCorrectFormat = locale !== 'es' || validateLicensePlateByLocale('es', value);
      return notEmpty(value) && hasCorrectFormat || errorMessages.licensePlate;
    },
    email(_ref13) {
      let {
        value,
        errorMessages
      } = _ref13;
      return isValidEmailPattern(value) ? true : errorMessages.email;
    }
  };
}
const defaultOthersValidators = {};
export function isEmpty(value) {
  // eslint-disable-next-line eqeqeq
  return value == undefined || value === '';
}
function notEmpty(value) {
  return !isEmpty(value);
}
function composeCustomValidators(fns) {
  const customValidator = function customValidator(_ref14) {
    let {
      validators,
      ...params
    } = _ref14;
    return fns.reduceRight((accumulator, fn) => {
      return fn({
        ...params,
        validators: accumulator
      });
    }, validators);
  };
  return customValidator;
}
function mileageIsOutOfBounds(mileage) {
  const maxMileageValue = 999999999;
  if (notEmpty(mileage) && Number(mileage) > maxMileageValue) {
    return true;
  }
  return false;
}