import type { CookieService } from './cookie-service.interface';
import type { IDataLayerService } from './data-layer-service.interface';
import { createDataLayerService } from './data-layer-service';

enum Category {
  ESSENTIAL = 'essential',
  MARKETING = 'marketing',
  ANALYTICAL = 'analytical',
}

interface GTMServiceConfig {
  cookieService: CookieService;
  dataLayerService: IDataLayerService;
  onStart: (script: string) => void;
  GTMId: string;
}

interface GDPRPreferenceCookie {
  categories: Category[];
}

type Permissions = Partial<{ [T in Category]: boolean }>;

type Permission = 'granted' | 'denied';
type GTMPermissions = {
  ad_storage: Permission;
  analytics_storage: Permission;
  ad_user_data: Permission;
  ad_personalization: Permission;
};

export class GTMService {
  private _cookieService: CookieService;
  private _dataLayerService: IDataLayerService;
  private _GTMId: string;
  private _onStart: (script: string) => void;

  get GTMId() {
    return this._GTMId;
  }

  constructor(config: GTMServiceConfig) {
    this._cookieService = config.cookieService;
    this._dataLayerService =
      config.dataLayerService || createDataLayerService();
    this._onStart = config.onStart;
    this._GTMId = config.GTMId;
  }

  private _startGTM() {
    this._onStart(this._dataLayerService.injectDataLayer(this.GTMId));
  }

  private _getPermissionsObject(permissions: Permissions): GTMPermissions {
    return {
      ad_storage: permissions.marketing ? 'granted' : 'denied',
      analytics_storage: permissions.analytical ? 'granted' : 'denied',
      ad_user_data: permissions.marketing ? 'granted' : 'denied',
      ad_personalization: permissions.marketing ? 'granted' : 'denied',
    };
  }

  private _updatePermissions(permissions: Permissions) {
    /**
     * Permissions data should be added as an `Arguments` object.
     * See: https://developers.google.com/tag-manager/consent#implementation_example
     */
    function asArguments(..._args: any[]) {
      return arguments;
    }

    this._dataLayerService.unshift(
      asArguments('consent', 'update', this._getPermissionsObject(permissions))
    );
  }

  private _getCookies() {
    return {
      gdprPreferences: this._cookieService.get(
        'gdpr_preferences'
      ) as GDPRPreferenceCookie,
      gdprConsent: this._cookieService.get('gdpr_consent'),
    };
  }

  private _fireConsentChangeEvent(permissions: Permissions) {
    this._dataLayerService.unshiftEvent('cookieConsentChanged', {
      value: this._getPermissionsObject(permissions),
    });
  }

  private _isValidCookies() {
    const { gdprPreferences } = this._getCookies();

    try {
      return gdprPreferences.categories.every((el) =>
        Object.values(Category).includes(el)
      );
    } catch {
      return false;
    }
  }

  private _updateAll() {
    const permissions = {
      marketing: true,
      analytical: true,
    };
    this._fireConsentChangeEvent(permissions);
    this._updatePermissions(permissions);

    this._startGTM();
  }

  private _updatePartial() {
    this._cookieService.set('gdpr_consent', 'categories_denied', {
      path: '/',
      sameSite: 'lax',
    });
  }

  init() {
    if (!this._isValidCookies()) return;
    const { gdprPreferences, gdprConsent } = this._getCookies();
    const permissions = {
      marketing: gdprPreferences.categories.includes(Category.MARKETING),
      analytical: gdprPreferences.categories.includes(Category.ANALYTICAL),
    };

    if (gdprConsent) {
      this._fireConsentChangeEvent(permissions);
    }

    this._updatePermissions(permissions);

    this._startGTM();
  }

  /**
   * @deprecated
   */
  startImmediately() {
    console.warn(
      'DEPRECATION WARNING: `GTMService.startImmediately` method will be removed in future versions. Use it only in cases when the GDPR Cookie Banner was not rolled out.'
    );
    this._startGTM();
  }

  update() {
    if (!this._isValidCookies()) return;
    const { gdprPreferences } = this._getCookies();

    if (
      gdprPreferences.categories.includes(Category.ANALYTICAL) &&
      gdprPreferences.categories.includes(Category.MARKETING)
    ) {
      this._updateAll();
    } else {
      this._updatePartial();
    }
  }
}
