import { useRef } from 'react';
import { GTMService, createDataLayerService } from '@wkda/gtm-service';
import { useModulesStore } from './useModules';
import { useAppConfiguration } from './useAppConfiguration';

/**
 * @param {Object} params
 * @param {(script: string) => void} params.onStart
 */
export function useGTM(_ref) {
  let {
    onStart
  } = _ref;
  const {
    locale,
    GTMIdByLocales
  } = useAppConfiguration();
  const {
    gdprPreferencesModule
  } = useModulesStore();
  const gtmService = useRef(new GTMService({
    cookieService: gdprPreferencesModule.cookieService,
    dataLayerService: createDataLayerService(),
    GTMId: GTMIdByLocales[locale],
    onStart
  }));
  return gtmService.current;
}