import Cookies from 'universal-cookie';
import { isGdprCookiePermited, getCookieCrossDomain, getOrigin } from '@wkda/funnel-utils';
import { ONE_YEAR_IN_SECONDS, WKDA_LEAD_COOKIE, SCID_COOKIE } from '../constants';
import { v4 as uuid } from 'uuid';
export async function isEmailUnique(queryClient, carleadHash, getEmailCount) {
  var _consumerCarleadData$;
  const consumerCarleadData = queryClient.getQueryData(['consumerCarlead', carleadHash]);
  const {
    count
  } = await getEmailCount((_consumerCarleadData$ = consumerCarleadData === null || consumerCarleadData === void 0 ? void 0 : consumerCarleadData.email) !== null && _consumerCarleadData$ !== void 0 ? _consumerCarleadData$ : '');
  return count === 1;
}
export function prefetchExperiment(queryClient, partialsModule, fullLocale, name, partialGroupId) {
  return queryClient.prefetchQuery({
    queryKey: ['experimentsScript', name],
    queryFn: () => partialsModule.getExperiment(fullLocale, name, undefined, partialGroupId)
  });
}
export function getWKDALeadCookie(req) {
  var _req$headers;
  const cookies = new Cookies(req === null || req === void 0 || (_req$headers = req.headers) === null || _req$headers === void 0 ? void 0 : _req$headers.cookie);
  return cookies.get(WKDA_LEAD_COOKIE);
}
export async function setWKDALeadCookie(appConfig, carleadHash, logger, req, res) {
  const {
    env
  } = appConfig;
  const cookieService = new Cookies(req === null || req === void 0 ? void 0 : req.cookies);
  const isPermitted = await isGdprCookiePermited(env, cookieService, WKDA_LEAD_COOKIE);
  const OPTIONS = {
    path: '/',
    maxAge: ONE_YEAR_IN_SECONDS * (Boolean(req) ? 1000 : 1),
    domain: getCookieCrossDomain(req)
  };
  logger === null || logger === void 0 || logger.info({
    message: "[SET WKDA COOKIE] isPermitted=" + isPermitted
  });
  if (isPermitted) {
    if (res) {
      res.cookie(WKDA_LEAD_COOKIE, carleadHash, OPTIONS);
    } else {
      cookieService.set(WKDA_LEAD_COOKIE, carleadHash, OPTIONS);
    }
  }
}
export function prefetchGdprCookieContent(queryClient, fetcher, fullLocale, cookieBannerName) {
  return queryClient.prefetchQuery({
    queryKey: ['cookieBanner', fullLocale, cookieBannerName],
    queryFn: () => fetcher(fullLocale, cookieBannerName)
  });
}
export async function setSCIDCookie(appConfig, logger, req, res) {
  const {
    env
  } = appConfig;
  const cookieService = new Cookies(req === null || req === void 0 ? void 0 : req.cookies);
  if (!cookieService.get(SCID_COOKIE)) {
    const trackingId = uuid().replace(/-/g, '');
    const isPermitted = await isGdprCookiePermited(env, cookieService, SCID_COOKIE);
    const OPTIONS = {
      path: '/',
      maxAge: 2 * ONE_YEAR_IN_SECONDS * (Boolean(req) ? 1000 : 1),
      domain: getCookieCrossDomain(req)
    };
    logger === null || logger === void 0 || logger.info({
      message: "[SET SCID COOKIE] isPermitted=" + isPermitted
    });
    if (isPermitted) {
      if (res) {
        res.cookie(SCID_COOKIE, trackingId, OPTIONS);
      } else {
        cookieService.set(SCID_COOKIE, trackingId, OPTIONS);
      }
    }
  }
}
export function redirectObfuscationURL(_ref) {
  let {
    req,
    obfuscationRedirectPath
  } = _ref;
  if (req) {
    return {
      redirectTo: "" + getOrigin(req) + obfuscationRedirectPath + "?gdprdeletion=true",
      statusCode: 303
    };
  } else {
    window.location.href = "" + getOrigin(req) + obfuscationRedirectPath + "?gdprdeletion=true";
  }
}