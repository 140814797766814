import { FORM_ERROR } from 'final-form';
import { useLingui } from '@lingui/react';
import { parseError, useResponseErrors } from '@wkda/funnel-components';
import { createErrorMessages } from '@wkda/carlead-creation-form';
import { getFieldSubmissionErrors } from '../getFieldSubmissionErrors';
function _getSubmissionErrors(error, i18n) {
  let errors = {};
  if (typeof error !== 'undefined') {
    let parsedError = parseError(error);
    if (!parsedError.hasError) {
      return {};
    }
    const {
      globalErrors = [],
      fieldErrors = []
    } = parsedError.data;
    const errorMessages = createErrorMessages(i18n);
    const unknownError = i18n._('carleadCreation.error.global.unknown', {}, {
      message: 'Unknown Error'
    });
    const formFieldErrors = getFieldSubmissionErrors(fieldErrors, errorMessages, unknownError);
    errors = {
      ...errors,
      ...formFieldErrors
    };
    if (globalErrors.length > 0) {
      errors[FORM_ERROR] = unknownError;
    }
  }
  return errors;
}
export function useCarleadConflicts() {
  const errors = useResponseErrors();
  const {
    i18n
  } = useLingui();
  return {
    getSubmissionErrors: error => _getSubmissionErrors(error, i18n),
    ...errors
  };
}