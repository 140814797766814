import { useLingui } from '@lingui/react';
import { createCarleadCreationForm, createErrorMessages, createQuestionnaires } from '@wkda/carlead-creation-form';
import { DataLayerEventStack } from '@wkda/data-layer';
import { useConstant } from '@wkda/funnel-components';
import { buildPathWithQuery, getQueryParams } from '@wkda/funnel-utils';
import { deepmerge } from 'deepmerge-ts';
import pick from 'lodash-es/pick';
import { CarleadFieldsMap } from '../../carleadFieldsMap';
import { useCarleadConfiguration } from '../useCarleadCreationConfiguration';
import { useCarleadCjTracking } from '../useCarleadCjTracking';
import { useCarleadDataLayer } from '../useCarleadDataLayer';
import { defaultCarleadFields } from './fieldLists';
const _defaultSteps = [[...defaultCarleadFields]];
export function useCreateCarleadForm(_ref) {
  let [first, ...rest] = _ref;
  const config = useCarleadConfiguration();
  const errorMessages = useCarleadErrorMessages();
  const {
    trackSuccess,
    trackError
  } = useCarleadCjTracking();
  const dataLayer = useCarleadDataLayer();
  const defaults = {
    config,
    errorMessages,
    questionnaireMappers: [],
    createSteps: [],
    lifecycleHooks: {
      onStepSubmitSuccess(_ref2) {
        let {
          isLastStep,
          carleadHash
        } = _ref2;
        if (!isLastStep) {
          return;
        }
        if (!carleadHash) {
          const message = 'Carlead hash is missing';
          console.error(message);
          throw new Error(message);
        }
        dataLayer.add({
          event: 'lead.cta.success'
        });
        new DataLayerEventStack(carleadHash).init();

        // Track successful lead creation with cjTracking
        trackSuccess(carleadHash);
        const targetUrl = config.redirectOnSuccessUrl.replace('{hash}', carleadHash);
        const res = buildPathWithQuery(targetUrl, getQueryParams(), config.whitelistedQueryParams);
        window.location.assign(res);
      },
      onValidationError: _ref3 => {
        let {
          values,
          errors,
          currentFields
        } = _ref3;
        const errorsToPush = pick(errors, currentFields);
        Object.keys(errorsToPush).forEach(key => {
          //@ts-expect-error
          const fieldName = CarleadFieldsMap.toDSB(key) || key;
          dataLayer.pushError(fieldName, values[key] ? 'INVALID' : 'REQUIRED');

          // Track lead creation field error with cjTracking
          trackError(fieldName, values[key] ? 'INVALID' : 'REQUIRED');
        });
      }
    }
  };
  const {
    questionnaireMappers,
    ...options
  } = deepmerge(defaults, first, ...rest, {
    createSteps: [function defaultSteps() {
      return _defaultSteps;
    }]
  });
  const carleadForm = createCarleadCreationForm({
    ...options,
    questionnaires: createQuestionnaires({
      config,
      mappers: questionnaireMappers
    })
  });
  return carleadForm;
}
function useCarleadErrorMessages() {
  const {
    i18n
  } = useLingui();
  const messages = useConstant(() => createErrorMessages(i18n));
  return messages;
}