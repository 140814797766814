import { useSelector } from '@xstate/react';
import { useFormMachine } from '../Form/CarleadCreationForm';
export function useIsStepEnabled(stepName, predicate) {
  const isCurrentStep = useIsCurrentFieldSelector(stepName, predicate);
  const stack = useHistoryStackSelector();
  const isPastField = stack.includes(stepName);
  return isCurrentStep || isPastField;
}
export function useHistoryStackSelector() {
  const machineFormService = useFormMachine();
  return useSelector(machineFormService, historyStackSelector);
}
export function useContextValuesSelector() {
  const machineFormService = useFormMachine();
  return useSelector(machineFormService, contextValuesSelector);
}
export function useContextValueForFieldSelector(fieldName) {
  return useContextValuesSelector()[fieldName];
}
export function useDataFieldsSelector() {
  const machineFormService = useFormMachine();
  return useSelector(machineFormService, dataFieldsSelector);
}
export function useQuestionnaireFieldsSelector() {
  const machineFormService = useFormMachine();
  return useSelector(machineFormService, questionnaireFieldsSelector);
}
export function useIsCurrentFieldSelector(name) {
  let predicate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : (i, n) => i.includes(n);
  const machineFormService = useFormMachine();
  return useSelector(machineFormService, isCurrentDataFieldSelector(name, predicate));
}
export function useResetToSelector() {
  const machineFormService = useFormMachine();
  return useSelector(machineFormService, selectResetTo);
}
export function useIsEditingPreviousFieldSelector(name) {
  const machineFormService = useFormMachine();
  const isEditingPreviousField = useSelector(machineFormService, isEditingPreviousFieldSelector(name));
  return isEditingPreviousField;
}
export function isEditingPreviousFieldSelector(name) {
  return function _isEditingPreviousFieldSelector(state) {
    var _Object$keys$find;
    const selectedFields = state.context.selectedFields;
    const previousFieldName = (_Object$keys$find = Object.keys(selectedFields).find(key => {
      return selectedFields[key] === name;
    })) !== null && _Object$keys$find !== void 0 ? _Object$keys$find : '';
    const previousFieldHasValue = !!state.context.values[previousFieldName];
    const isEditingPreviousField = getStateValueStrings(state.value).some(value => {
      return previousFieldName === '' ? false : value.includes(previousFieldName) && previousFieldHasValue;
    });
    return isEditingPreviousField;
  };
}
function contextValuesSelector(state) {
  return state.context.values;
}
function dataFieldsSelector(state) {
  return state.context.dataFields;
}
function questionnaireFieldsSelector(state) {
  return state.context.questionnaireFields;
}
function historyStackSelector(state) {
  return state.context.stack;
}
function isCurrentDataFieldSelector(name, predicate) {
  return function selector(state) {
    return getStateValueStrings(state.value).some(i => predicate(i, name));
  };
}
function selectResetTo(state) {
  return state.context.resetTo;
}
export function getStateValueStrings(stateValue) {
  if (typeof stateValue === 'string') {
    return [stateValue];
  }
  const valueKeys = Object.keys(stateValue);
  return valueKeys.concat(...valueKeys.map(key => getStateValueStrings(stateValue[key]).map(s => key + '.' + s)));
}