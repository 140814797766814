import React from 'react';
import { carleadCreationGetInitialProps } from '@wkda/carlead-creation-components';
import { BaseLeadPage, CarleadStepCompoundDefault, CarleadTopBlueCarHCSliderMap, } from '@wkda/marketing-components';

function CarleadCreation() {
  return (
    <BaseLeadPage
      form={CarleadStepCompoundDefault}
      content={CarleadTopBlueCarHCSliderMap}
      className='mobile-blue-bg'
      howItWorksShowCta={false}
      headlineDesktop='carleadCreation.form.title'
      headlineMobile='carleadCreation.form.title'
    />
  );
}

CarleadCreation.getInitialProps = carleadCreationGetInitialProps;

export default CarleadCreation;
