import { i18n as _i18n } from "@lingui/core";
export const getImageContent = () => {
  return {
    mapContent: {
      className: 'map-content',
      imageColumn: 'col-md-5 order-2',
      contentColumn: 'col-md-7 order-1',
      contentTitle: _i18n._(
      /*i18n*/
      {
        id: 'carlead.mapContent.title'
      }),
      contentSubtitle: _i18n._(
      /*i18n*/
      {
        id: 'carlead.mapContent.subtitle'
      }),
      image: {
        src: _i18n._(
        /*i18n*/
        {
          id: 'carlead.mapContent.image'
        }),
        title: _i18n._(
        /*i18n*/
        {
          id: 'carlead.mapContent.title'
        }),
        alt: _i18n._(
        /*i18n*/
        {
          id: 'carlead.mapContent.title'
        }),
        class: 'img-fluid'
      },
      cta: {
        label: _i18n._(
        /*i18n*/
        {
          id: 'carlead.mapContent.cta.label'
        }),
        href: _i18n._(
        /*i18n*/
        {
          id: 'carlead.mapContent.cta.href'
        })
      }
    }
  };
};