import { isFocusInputEvent, isSelectEvent } from '../../machineModels/eventGenerators';
export function valueIsEmpty(value) {
  return value == null || value === '';
}
export function valueIsNotEmpty(value) {
  return !valueIsEmpty(value);
}
export function hasPreloadedDataGuard(_ref, params) {
  let {
    context: {
      preloadedData
    }
  } = _ref;
  const fieldName = params.fieldName;
  const preloadedValue = preloadedData[fieldName];
  return valueIsNotEmpty(preloadedValue);
}
export function hasPreloadedDataForDataFieldGuard(_ref2, params) {
  var _evt$output;
  let {
    context: {
      preloadedData
    },
    event
  } = _ref2;
  const evt = event;
  const fieldName = params.fieldName;
  const preloadedValue = preloadedData[fieldName];
  const hasValueInResponse = valueIsNotEmpty(evt === null || evt === void 0 || (_evt$output = evt.output) === null || _evt$output === void 0 || (_evt$output = _evt$output.wkda) === null || _evt$output === void 0 ? void 0 : _evt$output[preloadedValue]);
  return valueIsNotEmpty(preloadedValue) && hasValueInResponse;
}
export function canSelectFieldGuard(_ref3, params) {
  let {
    event
  } = _ref3;
  if (!isSelectEvent(event)) {
    return false;
  }
  const {
    fieldName,
    selected
  } = event;
  return valueIsNotEmpty(selected) && valueIsNotEmpty(fieldName) && fieldName === params.fieldName;
}
export function shouldModifyFieldGuard(_ref4) {
  let {
    event,
    context: {
      values,
      stack
    }
  } = _ref4;
  if (!isSelectEvent(event)) {
    return false;
  }
  const {
    fieldName,
    selected
  } = event;
  const fieldPreviousValue = values[fieldName];
  return valueIsNotEmpty(fieldName) && valueIsNotEmpty(selected) && stack.includes(fieldName) && fieldPreviousValue !== selected;
}
export function shouldFocusInputGuard(_ref5) {
  let {
    event
  } = _ref5;
  if (!isFocusInputEvent(event)) {
    return false;
  }
  const {
    fieldName
  } = event;
  return valueIsNotEmpty(fieldName);
}
export function canSelectQuestionnaireFieldGuard(_ref6, params) {
  let {
    event
  } = _ref6;
  if (!isSelectEvent(event)) {
    return false;
  }
  const fromAnswers = params.fromAnswers;
  return fromAnswers.includes(event.selected);
}
export function canModifyFieldGuard(_ref7, params) {
  let {
    context
  } = _ref7;
  return context.resetTo === params.field;
}
export function canUpdateFieldGuard(_ref8, params) {
  let {
    context
  } = _ref8;
  return context.updatingField === params.fieldName;
}
export function shouldTransitionToNextStateWithValueGuard(_ref9, params) {
  let {
    context: {
      values,
      updatingField
    }
  } = _ref9;
  const fieldName = params.fieldName;
  const value = values[fieldName];
  return valueIsNotEmpty(value) && updatingField === '';
}
export function stepValueIsFromAnswersGuard(_ref10, params) {
  let {
    context: {
      values
    }
  } = _ref10;
  const value = values[params.fieldName];
  const fromAnswers = params.fromAnswers;
  return fromAnswers.includes(value);
}