import flow from 'lodash-es/flow';
export function mapToQuery(formValues) {
  return {
    manufacturer: formValues.manufacturer,
    model: formValues.mainType,
    builtYear: formValues.builtDate
  };
}
export function mapFromQuery(query) {
  return flow(removeTrailingSlash, mapQuery)(query);
}
function removeTrailingSlash(queryObject) {
  return !queryObject ? null : Object.keys(queryObject).reduce((acc, key) => {
    const value = acc[key];
    if (typeof value === 'string') {
      return value.endsWith('/') ? {
        ...acc,
        [key]: value.slice(0, -1)
      } : acc;
    }
    return acc;
  }, queryObject || {});
}
function mapQuery(queryObject) {
  return {
    manufacturer: queryObject === null || queryObject === void 0 ? void 0 : queryObject.manufacturer,
    mainType: queryObject === null || queryObject === void 0 ? void 0 : queryObject.model,
    builtDate: queryObject === null || queryObject === void 0 ? void 0 : queryObject.builtYear
  };
}